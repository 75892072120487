<template>
  <div class="">
    <div class="flex justify-between items-center">
      <div class="flex items-center">
        <img
          src="@/assets/svg/design/logo-small.svg"
          class="w-12 mr-2"
          alt="one2fan logo">

        <p class="font-semibold">
          {{ $t('common.premium') }}
        </p>
      </div>

      <button
        v-if="premiumSubsctiptionUser.publisherUser.subscribed"
        class="btn btn-primary px-3 py-0 text-base m-0 self-center min-w-[6rem] lowercase"
        @click="$refs.unsubscribeModal.openModal({ userId: premiumSubsctiptionUser.publisherUser.userId })">
        {{ $t('common.cancel') }}
      </button>

      <button
        v-else
        class="btn btn-secondary px-3 py-0 text-base m-0 self-center min-w-[6rem]"
        @click="subscribe({
          userId: premiumSubsctiptionUser.publisherUser.userId,
          price: premiumSubsctiptionUser.price,
          user: premiumSubsctiptionUser.publisherUser })">
        {{ premiumSubsctiptionUser.price ? `${premiumSubsctiptionUser.price}$/${$t('common.month')}` : $t('user.subscriptions.for_free') }}
      </button>
    </div>

    <Modal
      v-slot="{ values, closeModal }"
      ref="unsubscribeModal"
      name="Unsubscribe">
      <UnsubscribeContent
        :values="values"
        @unsubscribed="unsubscribed"
        @close="closeModal()"/>
    </Modal>

    <Modal
      ref="subscribeModal"
      v-slot="{ closeModal, isVisible }"
      name="Subscribe"
      position="bottom"
      rounded-class="rounded-t-2.5xl"
      overflow-class="overflow-y-auto"
      content-class="bg-white px-6 pt-5 pb-4"
      :has-indents="false">
      <SubscribeContent
        :creator="selectedUser"
        :is-visible="isVisible"
        :close="closeModal"
        @subscribed="test()"/>
    </Modal>
  </div>
</template>

<script>
import UnsubscribeContent from '@/components/ModalContents/UnsubscribeContent'
import { mapActions, mapState } from 'pinia'
import { useSubscriptionsStore } from '@/stores/subscriptions'
import app from '@/main'
import { useCreatorStore } from '@/stores/creator'
import SubscribeContent from '@/components/ModalContents/SubscribeContent'

export default {
  name: 'PremiumSubscription',
  components: {
    UnsubscribeContent,
    SubscribeContent
  },
  data () {
    return {
      loading: true
    }
  },
  computed: {
    ...mapState(useSubscriptionsStore, [
      'subscriptions'
    ]),
    premiumSubsctiptionUser () {
      return this.subscriptions.find(item => !!item.price)
    }
  },
  beforeMount () {
    this.loading = true

    this.subscriptionsGet().finally(() => {
      this.loading = false
    })
  },
  methods: {
    ...mapActions(useSubscriptionsStore, [
      'subscriptionsGet',
      'toggleSubscriptionStatus'
    ]),
    ...mapActions(useCreatorStore, [
      'purchaseSubscription'
    ]),
    subscribed (user) {
      this.toggleSubscriptionStatus(user.userId)
    },
    unsubscribed (user) {
      this.toggleSubscriptionStatus(user.userId)
    },
    async subscriptionCreate (userId) {
      const res = await app.wsp.sendRequest({
        data: {
          publisherUserId: userId
        },
        method: 'subscription.create'
      })

      if (res.error) {
        console.log(res.error.message)
      }

      const isProduction = process.env.NODE_ENV === 'production'

      if (isProduction) {
        // eslint-disable-next-line no-undef
        gtag('event', 'Submit', {
          event_category: 'Form',
          event_label: 'Subscription'
        })
      }

      this.toggleSubscriptionStatus(userId)
    },
    subscribe ({ userId, price = null, user = {} }) {
      if (price) {
        this.selectedUser = user

        if (this.selectedUser.username) {
          this.$refs.subscribeModal.openModal()
        }
      } else {
        this.subscriptionCreate(userId)
      }
    }
  }
}
</script>

