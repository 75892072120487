<template>
  <div class="pt-2 -mb-1">
    <div class="flex items-center justify-around gap-4">
      <router-link
        :to="'/@' + chatUser.username"
        class="py-0.5 text-[#808080] text-center w-max flex items-center gap-1 justify-center rounded border border-transparent focus:border-sky-300 focus:outline-none focus:ring-1 focus:ring-sky-300"
        @click="clientEventTrack({ typeName: 'chat_navigation__my_profile_clicked', userId: chatUser.userId })">
        <InlineSvg
          class="inline-block w-3.5 h-3.5"
          :src="require('@/assets/svg/design/profile.svg')"/>

        <span class="text-[13px] leading-3">{{ $t('nav.chat.my_profile') }}</span>
      </router-link>

      <router-link
        :to="`/chat/${chatId}/attachments`"
        class="py-0.5 text-[#808080] text-center w-max flex items-center gap-1 justify-center rounded border border-transparent focus:border-sky-300 focus:outline-none focus:ring-1 focus:ring-sky-300"
        @click="clientEventTrack({ typeName: 'chat_navigation__my_content_clicked', userId: chatUser.userId })">
        <InlineSvg
          class="inline-block w-4"
          :src="require('@/assets/svg/design/chat-info/attachments.svg')"/>

        <span class="text-[13px] leading-3">{{ $t('nav.chat.attachments') }}</span>
      </router-link>

      <button
        v-if="options.tipsEnabled"
        :disabled="chatUser.deleted"
        :class="{ 'cursor-not-allowed opacity-30': chatUser.deleted }"
        type="button"
        class="py-0.5 text-[#808080] text-center w-max flex gap-1 items-center justify-center rounded border border-transparent focus:border-sky-300 focus:outline-none focus:ring-1 focus:ring-sky-300"
        @click="$refs.tipsModal.openModal();clientEventTrack({ typeName: 'chat_navigation__support_me_clicked', userId: chatUser.userId })">
        <InlineSvg
          class="inline-block w-[1.125rem]"
          :src="require('@/assets/fontawesome/regular/money-bill.svg')"/>

        <span class="text-[13px] leading-3">{{ $t('nav.chat.support_me') }}</span>
      </button>
    </div>

    <TipsModal
      v-if="options.tipsEnabled && chatUser"
      ref="tipsModal"
      :show-button="false"
      :creator="chatUser"/>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useChatStore } from '@/stores/chat'
import { useAuthStore } from '@/stores/auth'
import TipsModal from '@/components/TipsModal.vue'
import { clientEventTrack } from '@/helpers/methods'

export default {
  name: 'ChatInformation',
  components: {
    TipsModal
  },
  emits: ['focus'],
  data () {
    return {}
  },
  computed: {
    ...mapState(useChatStore, [
      'chatUser',
      'chatId'
    ]),
    ...mapState(useAuthStore, [
      'options'
    ])
  },
  mounted () {
  },
  methods: {
    clientEventTrack: clientEventTrack,
    focusChatInput () {
      const input = document.getElementById('chat_input')

      input.focus()
    }
  }
}
</script>

