<template>
  <metainfo>
    <template #title="{ content }">{{ content }}</template>
    <template #og:site_name="{ content }">{{ content }}</template>
    <template #og:description="{ content }">{{ content }}</template>
  </metainfo>

  <!-- Show loader if there is no connection yet -->
  <div
    v-show="!allowContent"
    class="absolute h-full overflow-hidden w-full z-50 flex justify-center items-center">
    <Spinner size="big"/>
  </div>

  <template v-if="packageVersionChecked">
    <router-view :key="$route.fullPath"/>

    <Debug/>

    <Modal
      v-slot="{ closeModal }"
      ref="loginModal"
      name="Login"
      position="bottom"
      overflow-class="overflow-y-auto"
      rounded-class="rounded-t-2.5xl"
      :has-indents="false"
      :allow-close-by-overlay="true"
      @close="closeModal;loginIsVisible = false">
      <LoginContent @close="closeModal(); loginIsVisible = false"/>
    </Modal>

    <Modal
      v-slot="{ closeModal }"
      ref="signupModal"
      name="SignUp"
      position="bottom"
      overflow-class="overflow-y-auto"
      rounded-class="rounded-t-2.5xl"
      :has-indents="false"
      :allow-close-by-overlay="true"
      @close="closeModal; signupIsVisible = false">
      <SignupContent @close="closeModal(); signupIsVisible = false"/>
    </Modal>

    <Modal
      ref="modalRefresh"
      name="Refresh"
      :allow-close-by-overlay="false">
      <RefreshContent/>
    </Modal>

    <Modal
      v-slot="{ closeModal }"
      ref="AppealToPWAModal"
      name="AppealPWA"
      wrapper-class="mb-4"
      content-class="bg-white p-4"
      position="bottom"
      :has-indents="true">
      <AppealToPWAContent @close="closeModal"/>
    </Modal>

    <Modal
      v-if="0"
      v-slot="{ closeModal }"
      ref="cookiesReminder"
      name="CookiesReminder"
      position="bottom"
      wrapper-class="mb-4"
      :has-indents="true"
      :show-close-cross="false"
      :allow-close-by-overlay="false">
      <CookiesReminderContent @close="closeModal(); showCookiesReminder = false"/>
    </Modal>

    <QuizSurvey
      v-if="isAuthenticated"
      :key="$i18n.locale"/>

    <div id="modals"/>

    <InviteMessage/>
    <ErrorMessage/>
    <SuccessMessage/>
    <DevStatues/>
    <AppSignInTokenRefresh v-if="webViewEscape?.signinToken.nextRefreshTs"/>
  </template>
</template>

<script>

import Debug from '@/components/Debug'
import { useMeta } from 'vue-meta'
import Modal from '@/components/Functional/Modal'
import LoginContent from '@/components/ModalContents/LoginContent'
import SignupContent from '@/components/ModalContents/SignupContent'
import RefreshContent from '@/components/ModalContents/RefreshContent'
import { mapActions, mapState, mapWritableState } from 'pinia'
import { useAuthStore } from '@/stores/auth'
import { useSubscriptionsStore } from '@/stores/subscriptions'
import Spinner from '@/components/Functional/Spinner'
import InviteMessage from '@/components/Notifications/InviteMessage'
import ErrorMessage from '@/components/Notifications/ErrorMessage'
import SuccessMessage from '@/components/Notifications/SuccessMessage'
import { useUserStore } from '@/stores/user'
import helpers from '@/helpers'
import i18nHelpers from '@/i18nHelpers'
import AppealToPWAContent from '@/components/ModalContents/AppealToPWAContent'
import { useAppStore } from '@/stores/app'
import CookiesReminderContent from '@/components/ModalContents/CookiesReminderContent'
import { usePaymentStore } from '@/stores/payment'
import { useQuizStore } from '@/stores/quiz'
import QuizSurvey from '@/components/QuizSurvey'
import quizData from '@/mixins/quizData'
import uniqid from 'uniqid'
import dayjs from 'dayjs'
import DevStatues from '@/components/Functional/DevStatues.vue'
import { useEventListener } from '@vueuse/core'
import { redirectToNewDomain } from '@/helpers/utils.js'
import { isWebViewFullCheck } from '@/helpers/is-webview.js'
import AppSignInTokenRefresh from '@/components/App/AppSignInTokenRefresh.vue'


export default {
  name: 'App',
  components: {
    Debug,
    Modal,
    RefreshContent,
    LoginContent,
    SignupContent,
    Spinner,
    InviteMessage,
    ErrorMessage,
    SuccessMessage,
    AppealToPWAContent,
    CookiesReminderContent,
    QuizSurvey,
    DevStatues,
    AppSignInTokenRefresh
  },
  mixins: [quizData],
  setup () {
    useMeta({
      title: 'One2fan – get closer to the creators',
      og: {
        title: 'One2fan – get closer to the creators',
        description: 'Early access to content, impact on future content, exclusive content for subscribers',
        site_name: 'One2fan'
      }
    })
  },
  data () {
    return {
      packageVersionChecked: false,
      isFirstVisit: true
    }
  },
  computed: {
    ...mapState(useQuizStore, [
      'survey',
      'quizAllowed'
    ]),
    ...mapWritableState(useQuizStore, [
      'surveyWasOpened',
      'surveyOpen',
      'surveyCause'
    ]),
    ...mapState(usePaymentStore, [
      'paymentProcessor',
      'oneClickPurchaseLoading'
    ]),
    isDev () {
      return process.env.VUE_APP_IS_DEBUG === 'yes'
    },
    ...mapState(useAppStore, [
      'showAppealPWA',
      'isMobile',
      'supportedLocales',
      'isDatingEnabled',
      'openDisableEmailNotificationsPopup'
    ]),
    ...mapWritableState(useAppStore, [
      'pwaPromptsCounter',
      'isSafari',
      'isiOS',
      'isMobile',
      'selectedLocale',
      'showCookiesReminder',
      'currentTime'
    ]),
    ...mapState(useUserStore, [
      'user'
    ]),
    ...mapWritableState(useAuthStore, [
      'loginIsVisible',
      'signupIsVisible',
      'landingPath',
      'signUp',
      'isOnline',
      'userIsInactive',
      'webViewEscape'
    ]),
    ...mapState(useAuthStore, [
      'isTokenExist',
      'isConnected',
      'isAuthenticated',
      'signinToken',
      'webViewEscape',
      'canonicalHostname'
    ]),
    allowContent () {
      if (this.isTokenExist) {
        return this.isTokenExist && this.isConnected && !this.oneClickPurchaseLoading
      }
      else {
        return this.isConnected
      }
    }
  },
  watch: {
    async isConnected (value) {
      if (value && !this.isAuthenticated) {
        function generateUsername () {
          return `u_${String(Math.random()).padEnd(12, '0').slice(2, 12)}`
        }

        const queryString = window.location.search
        const urlQueryParams = new URLSearchParams(queryString)

        const signinToken = urlQueryParams.get('signin_token')
        const redirectToCanonicalClientOrigin = urlQueryParams.get('redirect_to_canonical_host')
        const fromWebview = urlQueryParams.get('from_webview')
        const canonicalHostname = urlQueryParams.get('hostname')
        const fromMirror = urlQueryParams.get('from_mirror')
        const currentDomain = window.location.hostname

        const appStore = useAppStore()

        // Firstly redirect to canonical host if not in webview
        if (redirectToCanonicalClientOrigin === '1' && canonicalHostname && currentDomain !== canonicalHostname && !(isWebViewFullCheck() || appStore.webViewSimulate)) {
          await redirectToNewDomain(`https://${canonicalHostname}`, {
            redirect_to_canonical_host: redirectToCanonicalClientOrigin,
            from_webview: fromWebview,
            from_mirror: fromMirror,
            signin_token: signinToken || this.signinToken
          })
        }

        // Log in if there is signin_token query param
        else if (this.signinToken || signinToken) {
          await this.startSession({
            signinToken: this.signinToken || signinToken,
            shouldRedirect: true,
            userCameFromWebview: (fromWebview === '1') && !(isWebViewFullCheck() || appStore.webViewSimulate),
            userCameFromMirror: fromMirror === '1'
          })

          if (fromMirror === '1') {
            urlQueryParams.delete('from_mirror')
            const newUrl = `${window.location.pathname}?${urlQueryParams.toString()}`

            window.history.replaceState(null, '', newUrl)
          }

          if (fromWebview === '1' && !(isWebViewFullCheck() || appStore.webViewSimulate)) {
            // Remove from_webview from query params but keep other
            urlQueryParams.delete('from_webview')
            const newUrl = `${window.location.pathname}?${urlQueryParams.toString()}`

            window.history.replaceState(null, '', newUrl)
          }
        }

        if (this.isAuthenticated) {
          return
        }

        function getCookie (name) {
          const value = `; ${document.cookie}`
          const parts = value.split(`; ${name}=`)
          if (parts.length === 2) return parts.pop().split(';').shift()
        }

        // SignUp after preland
        const prelandSignup = localStorage.getItem('preland-signup') || getCookie('preland-signup')

        if (prelandSignup) {
          localStorage.removeItem('preland-signup')
          document.cookie = 'preland-signup=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'

          await this.signUp({
            ...JSON.parse(prelandSignup),
            sex: 1,
            username: generateUsername()
          }).finally(() => {
            // eslint-disable-next-line no-undef
            gtag('event', 'Submit', {
              event_category: 'Form',
              event_label: 'Registration'
            })
          })

          if (this.isAuthenticated) {
            let prelandQuiz = localStorage.getItem('preland-quiz')

            if (prelandQuiz) {
              prelandQuiz = JSON.parse(prelandQuiz)
              localStorage.removeItem('preland-quiz')
              this.surveyWasOpened = true

              const name = this.user.name
              const messageToSend = this.messageAfterQuiz.get(prelandQuiz.number)(name, prelandQuiz.creatorName || '')
              const chatId = prelandQuiz.chatId
              const latestAnswerNumber = prelandQuiz.number

              if (chatId && messageToSend) {
                await this.sendMessage(messageToSend, chatId, latestAnswerNumber)

                setTimeout(() => {
                  this.$router.push(`/chat/${chatId}`)
                }, 300)
              }
            }
            else {
              if (this.survey.showAfterSignup && this.quizAllowed) {
                setTimeout(() => {
                  this.surveyOpen = true
                }, 100)
              }
            }
          }
        }

        const pathname = window.location.pathname

        if (!this.isTokenExist && !pathname.includes('chat')) {
          helpers.showSignUp()
        }
      }
    },
    paymentProcessor (value, oldValue) {
      if (oldValue) {
        this.resetPaymentMethods()
      }
    },
    signupIsVisible (value) {
      if (value) {
        this.$refs.signupModal.openModal()
      }
      else {
        this.$refs.signupModal.closeModal()
      }
    },
    loginIsVisible (value) {
      if (value) {
        this.$refs.loginModal.openModal()
      }
      else {
        this.$refs.loginModal.closeModal()
      }
    },
    pwaPromptsCounter (value) {
      if (value === 1 || value === 2) {
        this.$refs.AppealToPWAModal.openModal()
      }
    },
    '$i18n.locale': {
      handler (newLocale, oldLocale) {
        if (newLocale !== oldLocale) {
          this.selectedLocale = newLocale
          i18nHelpers.setDocumentLang(newLocale)
        }
      },
      immediate: false
    }
  },
  async beforeMount () {
    const packageVersion = localStorage.getItem('packageVersion')

    // Clear cache
    // https://blog.francium.tech/vue-js-cache-not-getting-cleared-in-production-on-deploy-656fcc5a85fe
    if (packageVersion !== process.env.PACKAGE_VERSION) {
      localStorage.setItem('packageVersion', process.env.PACKAGE_VERSION)
      await window.location.reload(true)
    }
    else {
      this.packageVersionChecked = true
    }
  },
  async created () {
    setInterval(() => {
      this.currentTime = dayjs().unix()
    }, 1000 * 10)

    if (this.packageVersionChecked) {
      if (!this.isMobile) {
        document.documentElement.style.overflowY = 'scroll'
      }

      helpers.beforeInstallPWAPrompt()
      await helpers.isPWAInstalled()

      const vm = this

      if (this.pwaPromptsCounter < 1 && this.showAppealPWA && this.isMobile) {
        setTimeout(() => {
          vm.pwaPromptsCounter = 1
        }, 1000 * 60)
      }
    }

    useEventListener(window, 'offline', () => {
      console.log('Offline')
      this.isOnline = false
    })

    useEventListener(window, 'online', async () => {
      console.log('Online')
      await new Promise(resolve => setTimeout(resolve, 100))

      this.isOnline = true

      if (this.isConnected || this.userIsInactive) {
        return
      }

      // Try to reconnect immediately after internet connection is back
      try {
        await this.connect()
        location.reload()
      } catch (e) {
        await this.reconnect()
      }
    })
  },
  async mounted () {
    if (this.packageVersionChecked && !this.isDev) {
      // Detect page being suspended and suggest to refresh
      let lastFired = Date.now()

      addEventListener('mousemove', () => {
        lastFired = Date.now()
      })

      addEventListener('touchstart', () => {
        lastFired = Date.now()
      })

      const timer = setInterval(() => {
        if ((Date.now() - lastFired > 1000 * 60 * 10)) {
          this.userIsInactive = true

          this.$refs.modalRefresh.openModal()

          clearInterval(timer)
        }
      }, 1000)
      // Inactivity detection end
    }
  },
  methods: {
    ...mapActions(usePaymentStore, [
      'resetPaymentMethods'
    ]),
    ...mapActions(useAuthStore, [
      'startSession'
    ]),
    ...mapActions(useSubscriptionsStore, [
      'subscriptionsGet'
    ]),
    async sendMessage (message, chatId, surveyResultId) {
      await this.wsp.sendRequest({
        data: {
          text: message,
          receiverId: chatId,
          clientId: uniqid(),
          ttlAfterRead: null,
          highlighted: true,
          surveyResultId: surveyResultId ? surveyResultId.toString() : null
        },
        method: 'message.send'
      })
    }
  }
}
</script>
