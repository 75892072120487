<template>
  <!-- Extra div needed to prevent hack in iOS with height -->
  <div
    v-show="isReady"
    class="chat">
    <ChatHeader/>

    <div
      class="chat-content"
      :class="{ 'bg-gray-50': isAuthenticated }">
      <template v-if="isAuthenticated">
        <ChatMessages ref="messages"/>
      </template>

      <template v-else>
        <ChatBodyPlaceholder/>
      </template>
    </div>

    <ChatSuggestSupport v-if="isPremiumSupportUser"/>

    <div
      v-if="(options.gifts && !isPremiumSupportUser) && options.gifts?.listStyle === 'row'"
      class="py-1 px-3.5 border-r border-l border-b border-gray-100">
      <GiftsStarter
        :creator="chatUser"
        type="row"/>
    </div>

    <div class="chat-form ios-prevent-flickering">
      <TheChatForm v-if="isReady"/>
    </div>

    <Modal
      v-slot="{ closeModal }"
      ref="notEnoughCreditsModal"
      name="NoCredits"
      position="bottom"
      overflow-class="overflow-y-auto"
      content-class="bg-white p-5"
      wrapper-class=""
      rounded-class="rounded-t-2.5xl"
      :show-close-cross="false"
      :has-indents="false">
      <NoCreditsContent @close="closeModal()"/>
    </modal>

    <Modal
      v-slot="{ closeModal }"
      ref="sendPhotoModal"
      name="SendPhoto">
      <SendPhotoContent
        :values="messageToBuy"
        @unlocked="unlockedAttachment"
        @close="closeModal"/>
    </Modal>

    <ChatStatusUpdate
      v-if="userStatusFeature.enabled && isAuthenticated && chatId"
      :chat-id="chatId"/>
  </div>
</template>

<script>

  import ChatHeader from '@/components/Chat/ChatHeader'
  import ChatMessages from '@/components/Chat/ChatMessages/ChatMessages'
  import TheChatForm from '@/components/Chat/ChatForm/TheChatForm'
  import Modal from '@/components/Functional/Modal'
  import NoCreditsContent from '@/components/ModalContents/chat/NoCreditsContent'
  import SendPhotoContent from '@/components/ModalContents/chat/SendPhotoContent'
  import { useMeta } from 'vue-meta'
  import { mapState, mapActions, mapWritableState } from 'pinia'
  import { useChatStore } from '@/stores/chat'
  import { useUserStore } from '@/stores/user'
  import { useAuthStore } from '@/stores/auth'
  import { useSubscriptionsStore } from '@/stores/subscriptions'
  import ChatBodyPlaceholder from '@/components/Chat/ChatBodyPlaceholder'
  import { usePaymentStore } from '@/stores/payment'
  import { clientEventTrack } from '@/helpers/methods'
  import ChatSuggestSupport from '@/components/Chat/ChatSuggestSupport.vue'
  import ChatStatusUpdate from '@/components/Chat/ChatStatusUpdate.vue'
  import { clearQueriesAndAddWebviewParams } from '@/helpers/app-helpers.js'
  import app from '@/main.js'
  import uniqid from 'uniqid'
  import GiftsStarter from '@/components/Gifts/GiftsStarter.vue'
  import { isNumber } from 'radash'

  export default {
    name: 'TheChat',
    components: {
      ChatHeader,
      ChatMessages,
      TheChatForm,
      Modal,
      NoCreditsContent,
      SendPhotoContent,
      ChatBodyPlaceholder,
      ChatSuggestSupport,
      ChatStatusUpdate,
      GiftsStarter
    },
    setup () {
      const chatStore = useChatStore()

      useMeta({
        title: `Chat with ${chatStore.chatUser.name}`,
        og: {
          title: `Chat with ${chatStore.chatUser.name}`,
          description: 'One2fan – get closer to the creators',
          site_name: 'Fancy',
          type: 'website'
        }
      })
    },
    data () {
      return {
        isReady: false
      }
    },
    computed: {
      ...mapState(useAuthStore, [
        'isAuthenticated',
        'signinToken',
        'options',
        'canonicalHostname'
      ]),
      ...mapWritableState(useAuthStore, [
        'loginIsVisible',
        'pathToRedirect'
      ]),
      ...mapState(useUserStore, [
        'userId',
        'balance',
        'premiumSupportAgentUserId',
        'features'
      ]),
      ...mapState(useChatStore, [
        'chatId',
        'filteredMessages',
        'sortedMessages',
        'messageToBuy',
        'chatUser'
      ]),
      ...mapWritableState(useChatStore, [
        'allMessages',
        'paginationState',
        'chatLoading',
        'messages'
      ]),
      ...mapWritableState(usePaymentStore, [
        'oneClickPurchaseLoading',
        'pathBeforeBuying'
      ]),
      ...mapState(usePaymentStore, [
        'activePaymentMethod',
        'attachmentPayments'
      ]),
      ...mapWritableState(useSubscriptionsStore, [
        'recentSubscriptions'
      ]),
      isPremiumSupportUser () {
        return this.premiumSupportAgentUserId.toString() === this.chatId
      },
      latestMessage () {
        if (this.sortedMessages.length) {
          return this.sortedMessages[this.sortedMessages.length - 1]
        }
        else {
          return null
        }
      },
      latestMessageId () {
        if (this.latestMessage) {
          return this.latestMessage.messageId
        }
        else {
          return null
        }
      },
      userStatusFeature () {
        return this.features.userStatus
      }
    },
    watch: {
      latestMessage (message) {
        if (message.senderId === this.userId && message.type === 3) return

        if (message && message?.status === 0) {
          if (this.isReady && !this.balance && !this.$route.query.messageId && !this.isPremiumSupportUser) {
            setTimeout(() => {
              this.pathBeforeBuying = '/chat/' + this.chatId
              this.$refs.notEnoughCreditsModal.openModal()
              this.clientEventTrack({ typeName: 'not_ehough_credits_popup_shown' })
            }, 300)
          }
        }
      },
      messageToBuy (value) {
        if (value) {
          //
          //
          //
          this.$refs.sendPhotoModal.openModal()
        }
      },
      async isAuthenticated (value) {
        if (value) {
          this.loginIsVisible = false

          await this.$nextTick()
          await this.messagesGet()

          if (this.latestMessageId) {
            await this.messagesMarkAsRead(this.latestMessageId)
          }
        }
      }
    },
    mounted () {
      if (this.isAuthenticated) {
        if (isNumber(this.balance) && this.balance === 0 && !this.$route.query.messageId && !this.isPremiumSupportUser) {
          setTimeout(() => {
            this.pathBeforeBuying = '/chat/' + this.chatId
            this.$refs.notEnoughCreditsModal.openModal()
            this.clientEventTrack({ typeName: 'not_ehough_credits_popup_shown' })
          }, 500)
        }

        if (this.recentSubscriptions.includes(this.chatId)) {
          this.recentSubscriptions = this.recentSubscriptions.filter((item) => item !== this.chatId)
        }
      }
      else {
        this.loginIsVisible = true
        this.pathToRedirect = `/chat/${this.chatId}`
      }
    },
    async created () {
      addEventListener('visibilitychange', this.onVisibilityChange)

      if (this.chatId && this.isAuthenticated) {
        this.allMessages[this.chatId] = []
        this.paginationState = ''
        await this.messagesGet()

        if (this.latestMessageId) {
          await this.messagesMarkAsRead(this.latestMessageId)
        }

        const query = this.$route.query

        if (query.sendGiftId) {
          app.wsp
            .sendRequest({
              data: {
                receiverUserId: this.chatUser.userId,
                clientId: uniqid(),
                giftId: query.sendGiftId,
                purchaseCreditsIfNotEnough: false
              },
              method: 'gift.send'
            })
        }

        // Check should we pay for attachment
        if ((query.senderUserId || query.receiverUserId) && query.messageId && query.cost) {
          this.oneClickPurchaseLoading = true

          await this.unlockAttachment({
            senderUserId: query.senderUserId,
            receiverUserId: query.receiverUserId,
            messageId: query.messageId,
            cost: query.cost
          })

          const newQuery = { ...this.$route.query }

          delete newQuery.senderUserId
          delete newQuery.receiverUserId
          delete newQuery.messageId
          delete newQuery.cost

          this.$router.replace({ query: newQuery })
        }

        this.oneClickPurchaseLoading = false

        await this.$nextTick()

        this.isReady = true
        this.chatLoading = false
      }
      else if (!this.isAuthenticated) {
        await this.$nextTick()

        this.isReady = true
        this.chatLoading = false
      }

      clearQueriesAndAddWebviewParams()
    },
    beforeUnmount () {
      removeEventListener('visibilitychange', this.onVisibilityChange)

      const chatStore = useChatStore()

      chatStore.$reset()
    },
    methods: {
      clientEventTrack: clientEventTrack,
      ...mapActions(useChatStore, [
        'userGet',
        'messagesGet',
        'messagesMarkAsRead'
      ]),
      ...mapActions(usePaymentStore, [
        'paymentMethodsGet',
        'messageUnlockAttachment'
      ]),
      onVisibilityChange () {
        if (!document.hidden && this.latestMessageId) {
          this.messagesMarkAsRead(this.latestMessageId)
        }
      },
      unlockedAttachment (message) {
        const index = this.messages.findIndex((item) => item.messageId === message.messageId)

        if (index !== -1) {
          this.messages.splice(index, 1, message)
        }
      },
      async unlockAttachment ({ senderUserId, receiverUserId, messageId, cost }) {
        if ((senderUserId || receiverUserId) && messageId && cost) {
          const data = {
            senderUserId: senderUserId || null,
            receiverUserId: receiverUserId || null,
            messageId: messageId,
            cost: cost
          }

          if (this.balance >= cost) {
            try {
              const res = await this.messageUnlockAttachment(data)

              await this.unlockedAttachment(res?.data.message)
            } catch (e) {
              console.log(e)
            }

            return
          }

          data.purchaseCreditsIfNotEnough = true

          // Re-check payments methods
          if (!this.activePaymentMethod.id) {
            await this.paymentMethodsGet()
          }

          if (this.activePaymentMethod.id) {
            data.paymentMethodId = this.activePaymentMethod.id

            try {
              const res = await this.messageUnlockAttachment(data)

              await this.unlockedAttachment(res?.data.message)
            } catch (e) {
              console.log(e)
            }
          }
        }
      }
    }
  }
</script>

<style scoped>
  .chat {
    @apply md:max-w-md mx-auto flex flex-col h-full;
  }

  .chat-content {
    @apply flex-grow overflow-x-hidden overflow-y-visible border border-gray-100
  }

  .chat-form {
    @apply flex-grow-0 border-l border-r border-gray-100 py-2
  }
</style>
