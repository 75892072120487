<template>
  <div>
    <template v-if="blurAlternativeEnabled">
      <ul
        v-if="images.length"
        class="grid gap-0.5 grid-cols-4">
        <li
          v-for="(item, index) in images"
          :key="index"
          class="square bg-gray-300 relative cursor-pointer"
          @click="openOrShowSubcribe((index > (isAuthenticated ? 6 : 4) && !userHasPaidSubscription), index)">
          <div
            v-if="item.locked && !item.videoUrl || oddLockedImagesIDs.includes(item.id)"
            class="bg-[#262626] h-full w-full absolute inset-0">
            <img
              class="w-full h-full pt-4 relative z-10"
              alt="Lock decoration"
              src="@/assets/svg/design/lock-smile-decoration.svg">
          </div>

          <img
            v-else
            class="w-full h-full object-center object-cover absolute inset-0"
            :title="item.description"
            :alt="item.description"
            :src="item?.imageUrls?.c160x160 || '/img/unknown_image.png'">

          <div
            v-if="oddLockedImagesIDs.includes(item.id)"
            class="absolute inset-0 h-full w-full flex items-center justify-center flex-col cursor-pointer">
            <span>👇</span>

            <span class="text-white text-xs whitespace-nowrap">
              {{ $t('posts.only_in_chat') }} 💬
            </span>
          </div>

          <Lock
            v-else-if="item.locked && !item.videoUrl"
            :has-blur="false"
            :price="item.price"/>
        </li>
      </ul>
    </template>

    <ul
      v-if="images.length"
      class="grid gap-0.5 grid-cols-4">
      <li
        v-for="(item, index) in images"
        :key="index"
        class="square bg-gray-300 relative cursor-pointer"
        @click="handlePost(oddLockedImagesIDs.includes(item.id), item.userId, index);">
        <img
          class="w-full h-full object-center object-cover absolute inset-0"
          :title="item.description"
          :alt="item.description"
          :src="item?.imageUrls?.c160x160 || '/img/unknown_image.png'">

        <div
          v-if="oddLockedImagesIDs.includes(item.id)"
          class="absolute inset-0 h-full w-full flex items-center justify-center  flex-col cursor-pointer">
          <span>👇</span>

          <span class="text-white text-xs whitespace-nowrap">
            {{ $t('posts.only_in_chat') }} 💬
          </span>
        </div>

        <Lock
          v-else-if="item.locked && !item.videoUrl"
          :price="item.price"/>
      </li>
    </ul>

    <div
      v-if="imagesLoadingStatus === 'loaded-all'"
      class="my-4 leading-10 w-full text-caption text-gray-400 text-center"
      :class="{'border-b border-grey-600': images.length > 0}">
      {{ images.length > 0 ? $t('common.end_of_list') : $t('common.no_content') }}
    </div>

    <div
      v-show="~imagesLoadingStatus.indexOf('loading')"
      class="h-24 w-full flex">
      <Spinner size="big"/>
    </div>

    <Observer @intersect="getUserPosts"/>

    <Modal
      ref="modalPosts"
      v-slot="{ isVisible, values, closeModal }"
      name="ThePostsSwiper"
      :is-rounded="false"
      :has-indents="false"
      :is-full-height="true"
      :show-close-cross="false"
      z-index-class="z-10"
      :show-desktop-close-cross="true"
      content-class="h-full bg-gray-700"
      position="center">
      <ThePostsSwiper
        v-if="isVisible"
        :values="values"
        :posts="images"
        :users="[creator]"
        :show-close="true"
        @slide-changed="slideChanged"
        @reach-end="reachEnd"
        @close="closeModal"
        @liked="liked"
        @shared="shared"
        @avatar-clicked="closeModal"
        @unlocked="unlocked"
        @subscribed="subscribed"/>
    </Modal>

    <Modal
      ref="subscribeModal"
      v-slot="{ closeModal, isVisible }"
      name="Subscribe modal"
      overflow-class="overflow-y-auto"
      position="bottom"
      rounded-class="rounded-t-2.5xl"
      content-class="bg-white px-6 pt-5 pb-4"
      :has-indents="false">
      <SubscribeContent
        :creator="creator"
        :is-visible="isVisible"
        :close="closeModal"
        @subscribed="subscribed"/>
    </Modal>
  </div>
</template>

<script>
  import Observer from '@/components/Functional/Observer'
  import Spinner from '@/components/Functional/Spinner'
  import Modal from '@/components/Functional/Modal'
  import Lock from '@/components/Functional/Lock'
  import { mapState, mapActions, mapWritableState } from 'pinia'
  import { useSubscriptionsStore } from '@/stores/subscriptions'
  import { useAuthStore } from '@/stores/auth'
  import { useCreatorStore } from '@/stores/creator'
  import SubscribeContent from '@/components/ModalContents/SubscribeContent'
  import helpers from '@/helpers'
  import uniqid from 'uniqid'
  import ThePostsSwiper from '@/components/PostsSwiper/ThePostsSwiper.vue'

  export default {
    name: 'Images',
    components: {
      Observer,
      Spinner,
      Modal,
      Lock,
      SubscribeContent,
      ThePostsSwiper
    },
    props: {
      username: {
        type: String,
        default: null,
        required: true
      }
    },
    computed: {
      ...mapState(useSubscriptionsStore, [
        'userHasPaidSubscription'
      ]),
      ...mapState(useAuthStore, [
        'isAuthenticated',
        'authOptions'
      ]),
      ...mapWritableState(useCreatorStore, [
        'creator',
        'imagesLoadingStatus',
        'images'
      ]),
      blurAlternativeEnabled () {
        return this.authOptions?.blurAlternativeEnabled
      },
      lockedImages () {
        return this.images.filter((item) => item.locked && !item.videoUrl && item.price)
      },
      oddLockedImagesIDs () {
        const odds = this.lockedImages.filter((item, index) => {
          return (index + 1) % 2 === 1
        })

        return odds.map((item) => item.id)
      }
    },
    beforeMount () {
      this.imagesLoadingStatus = ''
      this.images = []
    },
    mounted () {
      this.getUserPosts()
    },
    methods: {
      ...mapActions(useCreatorStore, [
        'userPostsGet',
        'shareCount',
        'likeToggle'
      ]),
      openOrShowSubcribe (isLocked, index) {
        if (this.isAuthenticated) {
          if (isLocked) {
            this.$refs.subscribeModal.openModal()
          }
          else {
            this.openPost(index)
          }
        }
        else {
          helpers.showOnbordingOrSignUp()
        }
      },
      async handlePost (isOnlyInChat, userId, index) {
        if (this.isAuthenticated) {
          if (isOnlyInChat) {
            if (this.creator.subscribed) {
              if (this.creator.subscribed) {
                await this.wsp.sendRequest({
                  data: {
                    text: 'Hi! I would like to see exclusive content available only in chat',
                    receiverId: userId,
                    clientId: uniqid(),
                    ttlAfterRead: null,
                    highlighted: true,
                    surveyResultId: '33033'
                  },
                  method: 'message.send'
                }).then(() => {
                  this.$router.push(`/chat/${userId}`)

                  // this.wsp.sendRequest({
                  //   data: {
                  //     questionNumber: "3",
                  //     answerNumber: "33033"
                  //   },
                  //   method: 'survey.questionAnswered'
                  // })
                })
              }
              else {
                this.$refs.subscribeModal.openModal()
              }
            }
            else {
              this.$refs.subscribeModal.openModal()
            }
          }
          else {
            this.openPost(index)
          }
        }
        else {
          helpers.showOnbordingOrSignUp()
        }
      },
      async getUserPosts () {
        if (~this.imagesLoadingStatus.indexOf('loading')) return

        if (this.imagesLoadingStatus !== 'loaded-all') {
          const params = {
            username: this.username,
            contentType: 'images',
            limit: 9,
            // beforeNumber: this.images ? this.images[this.images.length - 1]?.number : null
            orderBy: 'score',
            belowScore: this.images ? this.images[this.images.length - 1]?.score : null
          }

          // console.log('loading', this.images[this.images.length - 1], this.images[this.images.length - 1]?.belowScore)

          await this.userPostsGet(params)
        }
      },
      openPost (index) {
        this.$refs.modalPosts.openModal({ initialIndex: index })
      },
      async slideChanged (index) {
        if (index % 3 === 0) {
          if (this.images.length - index <= 3 && this.imagesLoadingStatus !== 'loaded-all') {
            await this.getUserPosts()
          }
        }
      },
      async reachEnd () {
        if (this.imagesLoadingStatus !== 'loaded-all') {
          await this.getUserPosts()
        }
      },
      async liked (postId) {
        this.likeToggle({
          postId,
          type: 'images'
        })
      },
      shared (postId) {
        this.shareCount({ type: 'images', postId: postId })
      },
      async unlocked (data) {
        const index = this.images.findIndex((item) => item.id === data?.post.id)

        if (index !== -1) {
          this.images.splice(index, 1, data?.post)
        }
      },
      async subscribed () {
        await this.$refs.modalPosts.closeModal()
        this.imagesLoadingStatus = ''
        this.images = []
        await this.getUserPosts()
        this.creator.subscribed = true
      }
    }
  }
</script>

<style>
  /*  Aspect ratio 1:1 hack: see here: https://www.aleksandrhovhannisyan.com/blog/css-aspect-ratio/ */
  .square {
    height: 0;
    padding-bottom: 100%;
    position: relative;
  }
</style>
