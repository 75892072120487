<template>
  <div
    v-if="post"
    class="h-full w-full flex flex-col justify-end">
    <div class="flex justify-between items-end px-4 pb-1.5">
      <div class="mb-1 w-4/6 post-shadow">
        <RatingsBadge
          v-if="user.rankingPositions"
          class="mb-1.5 whitespace-nowrap max-w-max"
          :ranking-positions="user.rankingPositions"
          :user-id="user.userId"/>

        <h3 class="text-white font-semibold flex items-center mb-2">
          <router-link
            v-if="user.userId"
            :to="`/@${user.username}`"
            @click="$emit('avatar-clicked')">
            @{{ user?.username }}
          </router-link>

          <InlineSvg
            v-if="user.tikTokFollowerCount"
            :src="require('@/assets/svg/design/verified.svg')"
            class="ml-1.5"/>
        </h3>

        <p class="text-sm text-white leading-5 play-prevent">
          <VRuntimeTemplate :template="processHashtags(post.description)"/>
        </p>
      </div>

      <div class="relative flex flex-col items-center justify-center play-prevent">
        <router-link
          v-if="user.userId"
          :to="`/@${user.username}`">
          <Avatar
            class="w-14 mb-4"
            :show-status="true"
            :user-item="user"
            @click="$emit('avatar-clicked');clientEventTrack({ typeName: 'post__profile_clicked', userId: user.userId })"/>
        </router-link>

        <!-- Likes -->
        <div class="post-shadow mb-4 flex flex-col items-center">
          <ImageLoader
            v-slot="{ setLoaded }"
            style="width: 34px; height: 36px"
            class="cursor-pointer"
            :class="[post.liked ? 'text-primary' : 'text-white']"
            @click="like">
            <InlineSvg
              :src="require('@/assets/svg/design/heart.svg')"
              class="mb-1.5"
              @loaded="setLoaded"/>
          </ImageLoader>

          <span class="text-white">{{ countNumberFormat(post.likeCount) }}</span>
        </div>

        <!-- Views counter -->
        <div class="post-shadow mb-4 flex flex-col items-center">
          <router-link v-if="isDev" :to="'/' + post.id">
            <ImageLoader
              v-slot="{ setLoaded }"
              style="width: 32px; height: 30px">
              <InlineSvg
                :src="require('@/assets/svg/design/eye.svg')"
                class="mb-1.5"
                @loaded="setLoaded"/>
            </ImageLoader>
          </router-link>

          <ImageLoader
            v-else
            v-slot="{ setLoaded }"
            style="width: 32px; height: 30px">
            <InlineSvg
              :src="require('@/assets/svg/design/eye.svg')"
              class="mb-1.5"
              @loaded="setLoaded"/>
          </ImageLoader>

          <span class="text-white">{{ countNumberFormat(post.viewCount) }}</span>
        </div>

        <!-- Share -->
        <div
          v-if="isAbleToShare"
          class="post-shadow flex flex-col items-center cursor-pointer"
          @click="share">
          <ImageLoader
            v-slot="{ setLoaded }">
            <InlineSvg
              :src="require('@/assets/svg/design/share.svg')"
              class="mb-1.5"
              @loaded="setLoaded"/>
          </ImageLoader>

          <span class="text-white">{{ countNumberFormat(post.shareCount) }}</span>
        </div>
      </div>
    </div>

    <div class="px-4 mb-7 mr-2 relative z-30">
      <template v-if="isAuthenticated">
        <div
          v-if="isAuthenticated"
          class="flex items-center gap-1">
          <div
            v-if="post.locked && post.price || !user.subscribed"
            class="flex items-center flex-wrap">
            <div class="mr-2">
              <button
                v-if="post.locked && post.price"
                class="btn-primary btn px-4 py-1 text-caption play-prevent"
                @click="unlock">
                <span class="button-text">
                  {{ $t('posts.unblock_post', { type: type, price: post.price }) }}
                </span>
              </button>

              <button
                v-else-if="!user.subscribed"
                class="btn-primary btn px-4 py-1 text-caption play-prevent"
                @click="subscribe">
                <span class="button-text">
                  <template v-if="subscriptionPlansFirstPrice || user.subscriptionPriceInCurrency">
                    {{ $t('creator.subscribe.for_price', { price: Number(subscriptionPriceInCurrency) }) }}
                  </template>

                  <template v-else>
                    {{ $t('creator.subscribe.follow_for_free') }}
                  </template>
                </span>
              </button>
            </div>

            <template v-if="!secretToken">
              <button
                v-if="!user.subscribed && authOptions.chatRequiresSubscription"
                class="relative post-shadow btn btn-sm px-3 py-1 my-2 play-prevent whitespace-nowrap border border-gray-200 text-sm text-gray-200 font-semibold hover:text-gray-700 hover:bg-gray-200"
                @click="subscribe(); messageMeClicked(user.userId, 'post')">
                {{ $t('chat.message.message_me') }}

                <span
                  v-if="user.unreadIncomingMessageCount"
                  class="inline-flex leading-[1.2rem] items-center px-2 py-0.5 rounded-full text-xs font-mono font-medium bg-red-400 text-white absolute top-0 right-0 transform -translate-y-[10px] translate-x-[10px]">
                  {{ user.unreadIncomingMessageCount }}
                </span>
              </button>

              <router-link
                v-else
                class="relative post-shadow btn btn-sm px-3 py-0.5 my-2 play-prevent whitespace-nowrap border border-gray-200 text-sm text-gray-200 font-semibold hover:text-gray-700 hover:bg-gray-200"
                :to="`/chat/${user.userId}`"
                @click="messageMe(user.userId); messageMeClicked(user.userId, 'post')">
                {{ $t('chat.message.message_me') }}

                <span
                  v-if="user.unreadIncomingMessageCount"
                  class="inline-flex leading-[1.2rem] items-center px-2 py-0.5 rounded-full text-xs font-mono font-medium bg-red-400 text-white absolute top-0 right-0 transform -translate-y-[10px] translate-x-[10px]">
                  {{ user.unreadIncomingMessageCount }}
                </span>
              </router-link>
            </template>
          </div>

          <div
            v-else-if="user.subscribed || !authOptions.chatRequiresSubscription"
            class="mr-2">
            <router-link
              class="relative post-shadow btn btn-sm px-8 py-1 my-2 play-prevent whitespace-nowrap border border-gray-200 text-sm text-gray-200 font-mono font-semibold hover:text-gray-700 hover:bg-gray-200"
              :to="`/chat/${user.userId}`"
              @click="messageMe(user.userId); messageMeClicked(user.userId, 'post')">
              {{ $t('chat.message.message_me') }}

              <span
                v-if="user.unreadIncomingMessageCount || showBadgeAfterSubscription"
                class="inline-flex leading-[1.2rem] items-center px-2 py-0.5 rounded-full text-xs font-mono font-medium bg-red-400 text-white absolute top-0 right-0 transform -translate-y-[10px] translate-x-[10px]">
                {{ user.unreadIncomingMessageCount ? user.unreadIncomingMessageCount : '1' }}
              </span>
            </router-link>
          </div>

          <GiftsStarter
            v-if="(options.gifts)"
            :creator="user"
            icon-class="text-gray-200 hover:text-gray-300"
            type="grid"/>

          <TipsModal
            v-else-if="options.tipsEnabled && user?.username"
            :key="post.id"
            :creator="user"
            icon-class="text-gray-200 hover:text-gray-300"
            :stroke-icon-color="'stroke-gray-100'"
            class="post-shadow play-prevent"/>
        </div>

        <!-- if not authenticated -->
        <template v-else>
          <button
            v-if="post.locked && post.price"
            class="btn-primary btn px-4 py-1 text-caption my-2 play-prevent"
            @click="showSignUp({ postId: post.id })">
            {{ $t('auth.signup.sing_up_for_unlock') }}
          </button>

          <button
            v-else-if="!secretToken"
            class="btn-primary btn px-4 py-1 text-caption my-2 play-prevent"
            @click="isiOS ? showSignUp({ postId: post.id }) : $router.push(`/chat/${user.userId}`); messageMe(user.userId)">
            {{ $t('chat.message.message_me') }}
          </button>
        </template>
      </template>
    </div>

    <Modal
      ref="subscribeModal"
      v-slot="{ closeModal, isVisible }"
      name="Subscribe"
      position="bottom"
      rounded-class="rounded-t-2.5xl"
      overflow-class="overflow-y-auto"
      content-class="bg-white px-6 pt-5 pb-4"
      :has-indents="false">
      <SubscribeContent
        :creator="user"
        :close="closeModal"
        :is-visible="isVisible"
        @modal-closed="$emit('modal-closed')"
        @subscribed="$emit('subscribed', $event)"/>
    </Modal>

    <Modal
      v-slot="{ closeModal }"
      ref="unlockPostContent"
      name="UnlockPost">
      <UnlockPostContent
        :post="post"
        @close="closeModal(); $emit('modal-closed')"
        @unlocked="$emit('unlocked', $event)"/>
    </Modal>
  </div>
</template>

<script>
import Avatar from '@/components/Functional/Avatar'
import VRuntimeTemplate from 'vue3-runtime-template'
import ImageLoader from '@/components/Functional/ImageLoader'
import Modal from '@/components/Functional/Modal'
import SubscribeContent from '@/components/ModalContents/SubscribeContent'
import UnlockPostContent from '@/components/ModalContents/post/UnlockPostContent'
import TipsModal from '@/components/TipsModal'
import { mapState, mapWritableState } from 'pinia'
import { useAuthStore } from '@/stores/auth'
import { useUserStore } from '@/stores/user'
import { useAppStore } from '@/stores/app'
import { useSubscriptionsStore } from '@/stores/subscriptions'
import helpers from '@/helpers'
import RatingsBadge from '@/components/Ranking/Common/RankingBadge.vue'
import { clientEventTrack } from '@/helpers/methods'
import GiftsStarter from '@/components/Gifts/GiftsStarter.vue'

export default {
  name: 'PostHandlers',
  components: {
    Avatar,
    VRuntimeTemplate,
    ImageLoader,
    Modal,
    SubscribeContent,
    UnlockPostContent,
    TipsModal,
    RatingsBadge,
    GiftsStarter
  },
  props: {
    post: {
      type: Object,
      default: null,
      required: true
    },
    user: {
      type: Object,
      default: () => ({
        userId: null,
        username: null
      }),
      required: true
    },
    like: {
      type: Function,
      default: null
    },
    share: {
      type: Function,
      default: null
    },
    messageMe: {
      type: Function,
      default: null
    }
  },
  emits: [
    'avatar-clicked',
    'subscribed',
    'unlocked',
    'messageSent',
    'pause',
    'modal-closed'
  ],
  data () {
    return {
      activeSub: null
    }
  },
  computed: {
    ...mapState(useAppStore, [
      'isiOS',
      'secretToken'
    ]),
    ...mapState(useAuthStore, [
      'loginIsVisible',
      'isAuthenticated',
      'isTokenExist',
      'options',
      'authOptions'
    ]),
    ...mapState(useUserStore, [
      'balance',
      'userId'
    ]),
    ...mapWritableState(useAuthStore, [
      'signupIsVisible',
      'pathToRedirect'
    ]),
    ...mapState(useSubscriptionsStore, [
      'recentSubscriptions',
      'userHasPaidSubscription'
    ]),
    showBadgeAfterSubscription () {
      return this.recentSubscriptions.includes(this.user.userId)
    },
    isAbleToShare () {
      return navigator.canShare
    },
    type () {
      return this.post.type === 1 ? this.$t('common.photo') : this.$t('common.video')
    },
    isDev () {
      return process.env.VUE_APP_IS_DEBUG === 'yes'
    },
    subscriptionPriceInCurrency () {
      return this.user.subscriptionPlans?.length ? this.user.subscriptionPlans[0].priceInCurrency : this.user.subscriptionPriceInCurrency
    },
    subscriptionPlansFirstPrice () {
      return this.user.subscriptionPlans?.length ? this.user.subscriptionPlans[0].priceInCurrency : null
    }
  },
  methods: {
    clientEventTrack: clientEventTrack,
    processHashtags (str) {
      //
      return str.replace(
        /#([\p{Letter}\d_]+)/gu,
        '<router-link class="font-semibold" :to="\'/discover/$1\'">#$1</router-link>'
      )
    },
    subscribe () {
      // If video post is partial - pause
      if (this.post.locked && this.post.videoUrl) {
        this.$emit('pause')
      }

      if (this.isTokenExist) {
        this.$refs.subscribeModal.openModal()
      } else {
        helpers.showSignUp()
      }
    },
    unlock () {
      // If video post is partial - pause
      if (this.post.locked && this.post.videoUrl) {
        this.$emit('pause')
      }

      if (this.isTokenExist) {
        this.$refs.unlockPostContent.openModal()
      } else {
        helpers.showSignUp()
      }
    },
    showSignUp ({ postId = null, userId = null }) {
      if (userId) {
        this.pathToRedirect = `/chat/${userId}`
      } else if (postId) {
        this.pathToRedirect = `/${postId}`
      }

      helpers.showSignUp()
    },
    async messageMeClicked (userId, clickSource) {
      const res = await this.wsp.sendRequest({
        data: {
          userId,
          clickSource
        },
        method: 'messageMe.clicked'
      })

      console.log(res)
    }
  }
}
</script>

<style scoped>
  .button-text {
    max-width: max-content;
    overflow: hidden;
    white-space: nowrap;
    display: block;
    text-overflow: ellipsis;
  }

  .btn-filled {
    @apply bg-primary hover:bg-primary-press shadow-sm text-white font-semibold
  }

  .btn-outline {
    @apply bg-transparent hover:bg-white hover:text-slate-700 shadow-sm text-white border-2 border-white font-semibold
  }
</style>
