<script setup>
  import { countNumberFormat } from '@/helpers/app-helpers.js'
  import RankingBadge from '@/components/Ranking/Common/RankingBadge.vue'
  import TipsModal from '@/components/TipsModal.vue'
  import { inject } from 'vue'
  import { clientEventTrack } from '@/helpers/methods.js'
  import GiftsStarter from '@/components/Gifts/GiftsStarter.vue'
  import { useAuthStore } from '@/stores/auth.js'
  import { storeToRefs } from 'pinia'

  const authStore = useAuthStore()

  const {
    options
  } = storeToRefs(authStore)

  defineProps({
    user: {
      type: Object,
      default: () => ({ photos: { items: [] } }),
      required: true
    },
    rank: {
      type: Number,
      default: 0,
      required: true
    }
  })

  const activeTabWithTimeframe = inject('activeTabWithTimeframe')
</script>

<template>
  <section class="w-full min-w-0 text-center flex flex-col items-center">
    <div
      :class="{
        'max-w-[5.25rem]' : rank === 2 || rank === 3,
        'max-w-[6.5rem]' : rank === 1,
      }"
      class="flex items-center justify-center mt-2 overflow-hidden">
      <h3
        :title="user.name"
        class="font-semibold text-center whitespace-nowrap overflow-hidden text-ellipsis text-sm">
        {{ user.name ? user.name.split(' ')[0] : '' }}
      </h3>

      <InlineSvg
        :src="require('@/assets/svg/design/verified.svg')"
        class="shrink-0 ml-0.5 w-4 h-4"/>
    </div>

    <div class="flex items-center justify-center gap-1 mb-1.5">
      <div v-if="user.tikTokFollowerCount" class="flex items-center gap-0.5">
        <InlineSvg
          :src="require('@/assets/fontawesome/regular/user.svg')"
          class="shrink-0 w-2.5 h-2.5 text-gray-500"/>

        <span class="text-gray-500 text-xs font-medium">
          {{ countNumberFormat(user.tikTokFollowerCount) }}
        </span>
      </div>

      <div
        v-if="user.rankingPositions[activeTabWithTimeframe]?.score"
        class="flex items-center gap-0.5 rounded px-0.5 bg-gray-100">
        <InlineSvg
          :src="require('@/assets/fontawesome/regular/gem.svg')"
          class="text-sky-600 shrink-0 w-2.5 h-2.5"/>

        <span v-if="user.rankingPositions[activeTabWithTimeframe]?.score" class="text-sky-600 text-xs font-medium">
          {{ countNumberFormat(user.rankingPositions[activeTabWithTimeframe].score) }}
        </span>
      </div>
    </div>

    <RankingBadge
      v-if="user.rankingPositions"
      class="my-2.5"
      :is-clickable="false"
      :ranking-positions="user.rankingPositions"/>

    <div class="flex items-center justify-center gap-2">
      <GiftsStarter
        v-if="options.gifts"
        v-slot="slotProps"
        :creator="user"
        icon-class="text-gray-400 hover:text-gray-500"
        type="grid">
        <button
          type="button"
          class="bg-gray-100 hover:bg-gray-200 p-1 rounded text-gray-500"
          @click="slotProps.openGiftsCatalogModal()">
          <InlineSvg
            :src="require('@/assets/fontawesome/regular/gift.svg')"
            class="w-4 h-4"/>
        </button>
      </GiftsStarter>

      <TipsModal
        v-else-if="options.tipsEnabled"
        v-slot="slotProps"
        :creator="user">
        <button
          type="button"
          class="bg-gray-100 hover:bg-gray-200 p-1 rounded text-gray-500"
          @click="slotProps.openModal();clientEventTrack({ typeName: 'ranking__tip_clicked', userId: user.userId })">
          <InlineSvg
            :src="require('@/assets/fontawesome/regular/money-bill.svg')"
            class="w-4 h-4"/>
        </button>
      </TipsModal>

      <router-link
        :to="`/chat/${user.userId}`"
        class="bg-brand p-1 rounded text-white hover:bg-brand-hover"
        @click="clientEventTrack({ typeName: 'ranking__start_chat_clicked', userId: user.userId })">
        <InlineSvg
          :src="require('@/assets/fontawesome/regular/paper-plane-top.svg')"
          class="w-4 h-4"/>
      </router-link>
    </div>
  </section>
</template>