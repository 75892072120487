<script setup>
  import { inject, ref, onMounted, onBeforeMount, computed, provide } from 'vue'
  import InlineSvg from 'vue-inline-svg'
  import app from '@/main.js'
  import GiftsItem from '@/components/Gifts/GiftsModal/GiftsItem.vue'
  import { useTimeout } from '@vueuse/core'
  import AppErrorMessage from '@/components/App/AppErrorMessage.vue'
  import emitter from '@/plugins/mittPlugin.js'

  const close = inject('close')

  emitter.on('close-gifts-to-send-modal', () => {
    close()
  })

  const props = defineProps({
    items: {
      type: Array,
      default: null,
      required: false
    },
    creator: {
      type: Object,
      default: () => ({}),
      required: true
    },
    openGiftsConfirmationSendModal: {
      type: Function,
      required: true
    },
    sendFreeGift: {
      type: Function,
      required: true
    }
  })

  provide('creator', props.creator)
  provide('openGiftsConfirmationSendModal', props.openGiftsConfirmationSendModal)
  provide('sendFreeGift', props.sendFreeGift)

  const gifts = ref([])

  onBeforeMount(() => {
    loading.value = false
    errorMessage.value = ''

    if (props.items) {
      gifts.value = props.items
    }
  })

  const loading = ref(false)
  const errorMessage = ref('')

  const { ready, start, stop } = useTimeout(500, { controls: true, immediate: false })

  const isLoading = computed(() => {
    return loading.value || !ready.value
  })
  
  onMounted(async () => {
    if (props.items) {
      return
    }

    try {
      if (isLoading.value) {
        return
      }

      loading.value = true
      start()
      
      const { data } = await app.wsp
        .sendRequest({
          data: {},
          method: 'gifts.get'
        })

      gifts.value = data.gifts
    } catch (error) {
      stop()

      console.log(error)
    } finally {
      loading.value = false
    }
  })
</script>

<template>
  <div
    class="rounded-t-xl bg-white p-6 shadow-lg w-full relative self-end max-h-full overflow-y-auto scrollbar-thin"
    @click.stop>
    <div class="mb-6 flex items-center gap-2 gap-y-5 justify-between">
      <h3 class="text-2xl">
        {{ app.$i18n.t('gifts.digital') }}
      </h3>

      <button
        class="text-gray-950 p-2 -mr-2"
        type="button"
        @click="close">
        <InlineSvg
          :src="require('@/assets/svg/design/cross.svg')"
          class="w-3 h-3"/>
      </button>
    </div>

    <AppErrorMessage
      v-if="errorMessage"
      class="my-2"
      :message="errorMessage"/>

    <div class="grid grid-cols-3 xs:grid-cols-4 gap-4">
      <template v-if="isLoading">
        <div 
          v-for="_ in 60"
          :key="_"
          class="flex flex-col items-center justify-center gap-2">
          <div class="h-12 xs:h-16 rounded-full skeleton-loading"/>

          <div class="flex items-center gap-1">
            <span class="h-3 w-6 skeleton-loading rounded-full"/>

            <span class="h-4 w-4 skeleton-loading rounded-full"/>
          </div>
        </div>
      </template>

      <GiftsItem
        v-for="item in gifts"
        v-else
        :key="item.id"
        :item="item"/>
    </div>
  </div>
</template>
