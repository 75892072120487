<script setup>
  import { inject, ref, computed } from 'vue'
  import InlineSvg from 'vue-inline-svg'
  import app from '@/main.js'
  import ValidationTooltip from '@/components/Functional/ValidationTooltip.vue'
  import { string, object } from 'yup-next'
  import { useTimeout } from '@vueuse/core'
  import { notify } from '@kyvg/vue3-notification'
  import { uid } from 'radash'
  import AppErrorMessage from '@/components/App/AppErrorMessage.vue'
  import { useUserStore } from '@/stores/user.js'

  const props = defineProps({
    callback: {
      type: Function,
      default: null,
      required: false
    }
  })

  const close = inject('close')
  const userStore = useUserStore()

  const loading = ref(false)
  const isSuccessful = ref(false)

  const errorMessage = ref('')

  const formValues = ref({
    email: null
  })

  const formErrors = ref({
    email: null
  })

  const { ready, start, stop } = useTimeout(500, { controls: true, immediate: false })

  const isLoading = computed(() => {
    return loading.value || !ready.value
  })

  const formSchema = object({
    email: string()
      .label(app.$i18n.t('forms.login.labels.email'))
      .email()
      .required()
  })


  async function checkEmail () {
    if (isLoading.value || isSuccessful.value) {
      return
    }

    loading.value = true
    start()

    try {
      await formSchema.validate(formValues.value, {
        abortEarly: false
      })
    } catch (error) {
      for (const item of error.inner) {
        if (!item.path) {
          continue
        }

        formErrors.value[item.path] = item.message
      }

      loading.value = false
      stop()

      return
    }

    try {
      const { data, error } = await app.wsp.sendRequest({
        data: {
          email: formValues.value.email
        },
        method: 'me.save'
      })

      errorMessage.value = ''

      if (error) {
        errorMessage.value = error?.message ? error.message : 'Unknown error'
        stop()
        loading.value = false
      } else {
        userStore.user = data
        isSuccessful.value = true

        notify({
          id: uid(6),
          group: 'success',
          title: app.$i18n.t('common.success'),
          text: app.$i18n.t('notifications.profile_updated')
        })

        setTimeout(() => {
          close()

          if (props.callback) {
            props.callback()
          }
        }, 1500)
      }
    } catch (error) {
      errorMessage.value = error?.message ? error.message : 'Unknown error'
      stop()
    } finally {
      loading.value = false
    }
  }

  const emailInput = ref(null)
</script>

<template>
  <div
    class="rounded-t-xl bg-white pt-6 px-6 pb-10 shadow-lg w-full relative self-end max-h-full overflow-y-auto scrollbar-thin"
    @click.stop>
    <div class="mb-4 flex gap-2 justify-between items-start">
      <h3 class="text-3xl">
        {{ app.$i18n.t('email.keep.in.touch') }}
      </h3>

      <button
        class="text-gray-950 p-2 -mr-2"
        type="button"
        @click="close">
        <InlineSvg
          :src="require('@/assets/svg/design/cross.svg')"
          class="w-3 h-3"/>
      </button>
    </div>

    <form @submit.prevent="checkEmail">
      <div class="flex items-center justify-between gap-2 mb-3">
        <label
          for="enter-your-email-input"
          class="text-gray-950">
          {{ app.$i18n.t('forms.login.labels.email') }}
        </label>

        <div class="relative w-full max-w-[66%]">
          <input
            id="enter-your-email-input"
            ref="emailInput"
            v-model="formValues.email"
            name="email"
            type="text"
            autocomplete="email"
            :disabled="isLoading || isSuccessful"
            :class="{ 'ring-2 ring-inset ring-red-300': formErrors.email }"
            :placeholder="app.$i18n.t('forms.login.placeholders.email')"
            class="w-full px-4 py-3 border-none placeholder-gray-400 bg-gray-100 rounded-sm focus:ring-2 focus:ring-inset focus:ring-blue-300"
            @focus="formErrors.email = ''">

          <ValidationTooltip
            :text="formErrors.email"
            @click="formErrors.email = '';emailInput.focus()"/>
        </div>
      </div>

      <AppErrorMessage
        v-if="errorMessage"
        class="mt-2"
        :message="errorMessage"/>

      <button
        :aria-disabled="isLoading || isSuccessful"
        :class="{ 'hover:bg-brand! cursor-default': isLoading || isSuccessful }"
        class="mt-6 px-6 py-3 w-full font-semibold text-white rounded-sm bg-brand hover:bg-brand-hover relative"
        type="submit">
        <span :class="{ 'invisible': isLoading || isSuccessful }">
          {{ app.$i18n.t('common.continue') }}
        </span>

        <span
          v-show="isLoading || isSuccessful"
          class="w-8 h-8 loader absolute inset-0 m-auto"/>
      </button>


      <div class="text-center text-xs mt-4">
        <a
          href="/terms"
          target="_blank"
          class="text-brand">
          {{ app.$i18n.t('common.terms_of_service') }}
        </a>

        {{ app.$i18n.t('common.and') }}

        <a
          href="/policy"
          target="_blank"
          class="text-brand">
          {{ app.$i18n.t('common.privacy_policy') }}
        </a>
      </div>
    </form>
  </div>
</template>