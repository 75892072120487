<template>
  <div class="mt-2 mb-0.5 relative">
    <div class="border-b border-[#d9d9d9] absolute bottom-0 left-0 right-0 w-full"/>

    <nav class="grid grid-cols-3 gap-0.5 relative z-10" aria-label="Tabs">
      <a
        :class="[
          activeTab === 'videos' ? 'border-primary text-primary' : 'border-transparent text-gray-400'
        ]"
        class="group inline-flex items-center justify-center py-2.5 px-1 border-b-2 cursor-pointer"
        @click="selectTab('videos');clientEventTrack({ typeName: 'profile__videos_tab_clicked', userId: creator.userId })">
        <InlineSvg
          :src="require('@/assets/svg/design/play-video.svg')"
          class="h-3 w-3"/>

        <span class="ml-2 text-caption capitalize">
          {{ $t('common.video') }}
        </span>
      </a>

      <a
        :class="[activeTab === 'images' ? 'border-primary text-primary' : 'border-transparent text-gray-400']"
        class="group inline-flex items-center justify-center py-2.5 px-1 border-b-2 cursor-pointer"
        @click="selectTab('images');clientEventTrack({ typeName: 'profile__images_tab_clicked', userId: creator.userId })">
        <InlineSvg
          :src="require('@/assets/svg/design/photo.svg')"
          class="h-3 w-3"/>

        <span class="ml-2 text-caption capitalize">
          {{ $t('common.photo') }}
        </span>
      </a>

      <a
        :class="[
          activeTab === 'info' ? 'border-primary text-primary' : 'border-transparent text-gray-400'
        ]"
        class="group inline-flex items-center justify-center py-2.5 px-1 border-b-2 cursor-pointer"
        @click="selectTab('info');clientEventTrack({ typeName: 'profile__info_tab_clicked', userId: creator.userId })">
        <InlineSvg
          :src="require('@/assets/svg/design/profile.svg')"
          class="h-3 w-3"/>

        <span class="ml-2 text-caption capitalize">{{ $t('common.profile') }}</span>
      </a>
    </nav>
  </div>

  <template v-if="creator.username">
    <transition name="fade" mode="out-in">
      <Videos
        v-if="activeTab === 'videos'"
        :username="creator.username"
        @update-tab="checkTab()"/>

      <Images
        v-else-if="activeTab === 'images'"
        :username="creator.username"/>

      <Info
        v-else-if="activeTab === 'info'"
        :creator="creator"/>
    </transition>
  </template>
</template>

<script>
import Videos from './Content/Videos'
import Images from './Content/Images'
import Info from './Content/Info'
import { mapActions, mapState, mapWritableState } from 'pinia'
import { useCreatorStore } from '@/stores/creator'
import { clientEventTrack } from '@/helpers/methods.js'

export default {
  name: 'CreatorContent',
  components: {
    Videos,
    Images,
    Info
  },
  data () {
    return {
      isVideoTabChecked: false
    }
  },
  computed: {
    ...mapState(useCreatorStore, [
      'creator'
    ]),
    ...mapWritableState(useCreatorStore, [
      'activeTab'
    ])
  },
  beforeUnmount () {
    if (process.env.NODE_ENV !== 'development') {
      useCreatorStore().$reset()
    }
  },
  created () {
    this.activeTab = 'videos'
  },
  methods: {
    ...mapActions(useCreatorStore, [
      'userPostsGet'
    ]),
    selectTab (string) {
      this.activeTab = string
    },
    checkTab () {
      if (!this.isVideoTabChecked) {
        this.selectTab('images')
        this.isVideoTabChecked = true
      }
    },
    clientEventTrack: clientEventTrack
  }
}
</script>
