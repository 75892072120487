<script setup>
  import { inject, computed, ref, onBeforeMount, onMounted, watch } from 'vue'
  import { useUserStore } from '@/stores/user.js'
  import { usePaymentStore } from '@/stores/payment.js'
  import { useAppStore } from '@/stores/app.js'
  import { storeToRefs } from 'pinia'
  import app from '@/main.js'
  import InlineSvg from 'vue-inline-svg'
  import ValidationTooltip from '@/components/Functional/ValidationTooltip.vue'
  import Spinner from '@/components/Functional/Spinner.vue'
  import AlertMessage from '@/components/Functional/AlertMessage.vue'
  import uniqid from 'uniqid'
  import emitter from '@/plugins/mittPlugin.js'
  import AppPaymentEmailForm from '@/components/App/AppPaymentEmailForm.vue'

  const props = defineProps({
    gift: {
      type: Object,
      default: () => ({}),
      required: true
    },
    creator: {
      type: Object,
      default: () => ({}),
      required: true
    }
  })

  const close = inject('close')
  const userStore = useUserStore()
  const paymentStore = usePaymentStore()
  const appStore = useAppStore()

  const {
    balance,
    user
  } = storeToRefs(userStore)

  const {
    endlessPaymentCreated
  } = storeToRefs(appStore)

  const {
    paymentMethods,
    activePaymentMethod,
    giftPayments,
    paymentProcessor,
    showWarnUpdatingCard
  } = storeToRefs(paymentStore)

  const isEnoughMoney = balance.value >= props.gift.priceInCredits

  const termsCheckbox = ref(false)
  const termsError = ref('')

  const termsOfService = computed(() => {
    return `<a
              href="/terms"
              target="_blank"
              class="underline">${app.$i18n.t('common.terms_of_service')}</a>`
  })

  const privacyPolicy = computed(() => {
    return `<a
              href="/policy"
              target="_blank"
              class="underline">${app.$i18n.t('common.privacy_policy')}</a>`
  })

  const termsText = computed(() => {
    return app.$i18n.t('creator.unlock.post.terms_agreement', {
      terms_of_service: termsOfService.value,
      privacy_policy: privacyPolicy.value
    })
  })

  const giftPayment = computed(() => {
    return giftPayments.value.find(item => item.id.includes('gift_' + props.gift.id))
  })

  const isPending = computed(() => {
    return giftPayment.value && ['created', 'payment_pending', 'payment_accepted'].includes(giftPayment.value.status)
  })

  const isFailed = computed(() => {
    return giftPayment.value && ['failed', 'cancelled'].includes(giftPayment.value.status)
  })

  const isLoading = ref(false)
  const successMessage = ref(null)
  const errorMessage = ref('')
  const errorDescription = ref('')
  const initialLoading = ref(true)
  const emailForm = ref(null)

  onBeforeMount(async () => {
    if (giftPayment.value) {
      const purchaseId = giftPayment.value.purchaseId
      await paymentStore.purchaseGet(purchaseId)

      if (giftPayment.value.status === 'created') {
        await paymentStore.purchaseCancel(purchaseId)
          .then(() => {
            paymentStore.purchaseDelete(purchaseId)
          })
      }
    }

    initialLoading.value = false
  })

  onMounted(async () => {
    // Update balance
    await userStore.meGet()
  })


  async function giftsSend (data) {
    let errorCode = null

    try {
      const response = await app.wsp
        .sendRequest({
          data: data,
          method: 'gift.send'
        })

      if (response.error) {
        const error = response.error
        errorCode = error.code

        throw new Error(error?.message ? error.message : 'Unknown error', {
          cause: error
        })
      }

      errorMessage.value = null
      errorDescription.value = null
      successMessage.value = 'Payment successful'
      isLoading.value = false

      setTimeout(() => {
        emitter.emit('close-gifts-to-send-modal')
        close()
      }, 1500)
    } catch (error) {
      // Failed to accept the payment (purchase failed)
      if (errorCode === 147) {
        errorMessage.value = error?.message
        errorDescription.value = error?.description
      }
      // The product is already purchased (purchase failed)
      else if (errorCode === 105) {
        errorMessage.value = 'Product is already purchased'

        setTimeout(() => {
          close()
        }, 2000)
      }
      // Payment is pending
      else if (errorCode === 149) {
        //
      }
      else if (errorCode === 150) {
        await new Promise(resolve => setTimeout(resolve, 1000 * 3))
        await paymentStore.purchaseGet(data.purchaseId)

        if (giftPayment.value === 'succeeded') {
          errorMessage.value = null
          errorDescription.value = null
          successMessage.value = 'Payment successful'
        }
        else {
          errorMessage.value = error?.message
          errorDescription.value = error?.description
        }
      }
      else {
        errorMessage.value = error?.message
        errorDescription.value = error?.description
      }

      // Remove payment
      if ([147, 105].includes(errorCode)) {
        paymentStore.purchaseDelete(giftPayment.value.purchaseId)
      }
    } finally {
      if (giftPayment.value) {
        await new Promise(resolve => setTimeout(resolve, 1000))
        await paymentStore.purchaseGet(giftPayment.value.purchaseId)

        // If purchase not completed -> wait n seconds to show loading
        if (!['succeeded', 'failed', 'cancelled'].includes(giftPayment.value?.status)) {
          await new Promise(resolve => setTimeout(resolve, 1000 * 10))
        }
      }

      isLoading.value = false
    }
  }

  async function buyGift () {
    if (isLoading.value) {
      return
    }

    isLoading.value = true
    errorMessage.value = null
    errorDescription.value = null
    successMessage.value = null
    termsError.value = ''

    const data = {
      receiverUserId: props.creator.userId,
      clientId: uniqid(),
      giftId: props.gift.id
    }

    // If enough credits -> send gift
    if (balance.value >= props.gift.priceInCredits) {
      data.purchaseCreditsIfNotEnough = false

      await giftsSend(data)
    }
    // If payment method exists -> send gift
    else if (!isEnoughMoney && activePaymentMethod.value.id) {
      // Check payment status or init one
      if (giftPayment.value) {
        await paymentStore.purchaseGet(giftPayment.value.purchaseId)
      }
      else {
        await paymentStore.purchaseCreate({ id: `gift_${props.gift.id}_${uniqid()}` })
      }

      if (endlessPaymentCreated.value) {
        await new Promise(resolve => setTimeout(resolve, 1000 * 5))
        isLoading.value = false
        return
      }

      data.purchaseCreditsIfNotEnough = true
      data.paymentMethodId = activePaymentMethod.value.id
      data.paymentProcessorId = paymentProcessor.value

      if (giftPayment.value?.purchaseId) {
        data.purchaseId = giftPayment.value.purchaseId
      }

      // Remove failed purchase if payment initialized again
      if (['failed', 'cancelled'].includes(giftPayment.value?.status)) {
        paymentStore.purchaseDelete(giftPayment.value.purchaseId)
      }

      await giftsSend(data)
    }
    // If no credits and no card
    else {
      // Check terms
      if (!termsCheckbox.value) {
        termsError.value = app.$i18n.t('yup.custom.terms_and_conditions')
        isLoading.value = false
      }

      // Check email
      if (!user.value.email && !isEnoughMoney) {
        const hasEmail = await emailForm.value.checkEmail()

        if (!hasEmail) {
          isLoading.value = false
        }
      }

      if (!termsCheckbox.value || !user.value.email) {
        return
      }

      await paymentStore.checkoutSessionCreate({
        successUrl: `${window.origin}/chat/${props.creator.userId}?sendGiftId=${props.gift.id}`,
        cancelUrl: `${window.origin}/chat/`,
        savePaymentMethod: true,
        productType: 'credits',
        productId: props.gift.priceInCredits
      })
    }
  }

  const termsCheckboxInput = ref(null)

  watch(() => termsCheckbox.value, (value, oldValue) => {
    if (!oldValue && value) {
      termsError.value = ''
    }
  })
</script>

<template>
  <form
    class="rounded-xl bg-white p-6 shadow-lg w-full mx-4"
    @submit.prevent="buyGift"
    @click.stop>
    <div class="mb-6 flex items-center gap-2 gap-y-5 justify-between">
      <h3 class="text-2xl">
        {{ app.$i18n.t('gifts.send') }}
      </h3>

      <button
        class="text-gray-950 p-2 -mr-2"
        type="button"
        @click="close">
        <InlineSvg
          :src="require('@/assets/svg/design/cross.svg')"
          class="w-3 h-3"/>
      </button>
    </div>

    <p
      v-if="isEnoughMoney"
      class="mb-4">
      {{ app.$i18n.t('chat.attachments.confirm_purchase', { price: gift.priceInCredits }) }}
    </p>

    <p v-else class="mb-4">
      <span v-html="app.$i18n.t('chat.attachments.confirm_top_up', { cost: gift.priceInCredits, price_in_currency: gift.priceInCredits })"/>
    </p>

    <!-- Show terms checkbox if no saved card and no credits -->
    <div
      v-if="!paymentMethods.length && !isEnoughMoney"
      class="flex items-start mb-8 relative">
      <div class="h-5 flex items-center">
        <input
          id="gift-terms"
          ref="termsCheckboxInput"
          v-model="termsCheckbox"
          type="checkbox"
          name="terms"
          class="mt-1.5 h-4 w-4 text-primary border border-primary rounded focus:ring-blue-300">
      </div>

      <div class="ml-3 font-medium text-gray-600 text-caption leading-4">
        <label
          for="gift-terms"
          v-html="termsText"/>
      </div>

      <ValidationTooltip
        :text="termsError"
        @click="termsError = '';termsCheckboxInput.focus(); termsCheckbox = true"/>
    </div>

    <!-- Pay with card if card there is -->
    <div
      v-if="paymentMethods.length && !isEnoughMoney"
      class="border-t text-sm border-gray-200 py-2 mb-2 flex flex-wrap items-center whitespace-nowrap justify-between">
      <span class="text-gray-500">
        {{ app.$i18n.t('user.your_cards.heading', 1) }}
      </span>

      <div class="flex justify-end items-center">
        <span class="ml-2 font-mono text-caption text-gray-500">
          <template v-if="paymentProcessor === 'stripe'">
            **** **** **** {{ activePaymentMethod.card.last4 }}
          </template>

          <template v-else-if="paymentProcessor === 'exactly'">
            {{ activePaymentMethod.card.maskedNumber }}
          </template>
        </span>
      </div>
    </div>

    <!-- States of action end -->
    <template v-if="!isLoading && !initialLoading">
      <AlertMessage
        v-if="successMessage"
        type="success"
        class="mb-2.5"
        :heading="successMessage"/>

      <AlertMessage
        v-else-if="isFailed || (errorMessage && !isPending)"
        type="error"
        class="mb-2.5"
        :heading="errorMessage || 'Previous transaction failed'"
        :text="errorDescription"/>

      <AlertMessage
        v-else-if="isPending"
        type="warning"
        class="mb-2.5"
        heading="Transaction pending, check later or contact online support"/>

      <AlertMessage
        v-else-if="showWarnUpdatingCard && !isEnoughMoney"
        type="info"
        class="mb-3 -mt-4"
        heading="When making a payment, you will have to update your card details once more!"/>
    </template>

    <AppPaymentEmailForm
      v-if="!user.email && !isEnoughMoney"
      ref="emailForm"
      class="my-4"/>

    <button
      type="submit"
      class="btn btn-block btn-primary mb-3 relative"
      :disabled="isLoading || isPending || successMessage">
      <Spinner
        v-show="isLoading"
        class="absolute inset-0 m-auto"/>

      <span :class="{ 'invisible': isLoading }">
        {{ app.$i18n.t('common.confirm') }}
      </span>
    </button>

    <!--<pre v-if="giftPayment">{{ giftPayment }}</pre>-->

    <button
      type="button"
      class="btn btn-block btn-secondary"
      :disabled="isLoading"
      @click="close">
      {{ app.$i18n.t('common.cancel') }}
    </button>
  </form>
</template>