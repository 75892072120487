<template>
  <div class="container h-screen flex flex-col justify-between items-center py-4">
    &#8203;

    <!-- Failure -->
    <template v-if="is === 'cancel'">
      <div class="flex flex-col items-center justify-center">
        <svg
          width="62"
          height="62"
          class="mb-4"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M60 31c0 16.016-12.984 29-29 29S2 47.016 2 31 14.984 2 31 2s29 12.984 29 29zm2 0c0 17.12-13.88 31-31 31C13.88 62 0 48.12 0 31 0 13.88 13.88 0 31 0c17.12 0 31 13.88 31 31zM32.084 20.848h-2.197l.175 14.458h1.86l.162-14.458zm-1.099 21.357c.479 0 .87-.151 1.172-.454.313-.313.469-.708.469-1.186 0-.489-.156-.884-.469-1.187-.302-.313-.693-.469-1.172-.469-.478 0-.874.156-1.186.469-.303.312-.454.708-.454 1.187 0 .478.151.873.454 1.186.312.303.708.454 1.186.454z"
            fill="#FF3B30"/>
        </svg>
        <template v-if="type === 'add-card'">
          <h1 class="text-lg mb-2">
            <span>{{ $t('user.payment.card_adding_cancelled') }}</span>
          </h1>

          <p class="text-center">
            <span v-html="$t('user.payment.you_declined_adding_card')"/>
          </p>
        </template>

        <template v-else>
          <h1 class="text-lg mb-2">
            <span>{{ $t('user.payment.purchase_cancelled') }}</span>
          </h1>

          <p class="text-center">
            <span v-html="$t('user.payment.you_cancelled_purchase')"/>
          </p>
        </template>
      </div>
    </template>

    <!-- Success -->
    <template v-if="is === 'success'">
      <div class="flex flex-col items-center justify-center">
        <svg
          width="62"
          height="62"
          class="mb-4"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M60 31c0 16.016-12.984 29-29 29S2 47.016 2 31 14.984 2 31 2s29 12.984 29 29zm2 0c0 17.12-13.88 31-31 31C13.88 62 0 48.12 0 31 0 13.88 13.88 0 31 0c17.12 0 31 13.88 31 31zm-16.218-7.376a1 1 0 0 0-1.564-1.248L29.78 40.472 18.731 28.65a1 1 0 1 0-1.462 1.366l11.84 12.667.79.845.722-.904 15.16-19z"
            fill="#00D796"/>
        </svg>

        <h1 class="text-lg text-center mb-2">
          <span v-if="type === 'subscription'">
            <span>{{ $t('user.payment.thank_for_subscribing') }}</span>
          </span>

          <span v-else-if="type === 'credits'">
            <span v-html="$t('user.payment.thank_for_purchase')"/>
          </span>

          <span v-else-if="type === 'add-card'">
            <span v-html="$t('user.payment.card_added_successfully')"/>
          </span>

          <span v-else-if="type === 'tip'">
            {{ $t('creator.tips.donate_sent') }}
          </span>
        </h1>

        <p class="text-center">
          <span v-html="$t('user.payment.service_will_be_available')"/>
        </p>
      </div>
    </template>

    <!-- Nothing happened -->
    <template v-else>
      &#8203;
    </template>

    <router-link
      :to="pathToContinue"
      class="btn btn-primary btn-block"
      @click="pathBeforeBuying = null">
      <span>{{ $t('common.continue') }}</span>
    </router-link>
  </div>
</template>

<script>

import { mapActions, mapWritableState } from 'pinia'
import { usePaymentStore } from '@/stores/payment'
import { useUserStore } from '@/stores/user'
import { useSubscriptionsStore } from '@/stores/subscriptions'
import helpers from '@/helpers'

let timeout = null

export default {
  name: 'StripeCheckoutSuccess',
  components: {},
  data () {
    return {
      // Route's query params
      is: null, // success|cancel
      type: null, // credits|subscription|tip
      userId: null,
      cost: null
    }
  },
  computed: {
    ...mapWritableState(usePaymentStore, [
      'pathBeforeBuying'
    ]),
    ...mapWritableState(useSubscriptionsStore, [
      'recentSubscriptions'
    ]),
    pathToContinue () {
      if (this.pathBeforeBuying) {
        return this.pathBeforeBuying
      } else {
        return '/'
      }
    }
  },
  beforeMount () {
    this.is = this.$route.query.is
    this.type = this.$route.query.type
    this.userId = this.$route.query.userId
    this.cost = this.$route.query.cost
  },
  beforeUnmount () {
    clearTimeout(timeout)
  },
  mounted () {
    this.meGet()

    setTimeout(() => {
      if (this.userId) {
        if (!this.recentSubscriptions.includes(this.userId)) {
          this.recentSubscriptions.push(this.userId)
        }
      }
    }, 8000)

    timeout = setTimeout(() => {
      if (this.pathBeforeBuying) {
        this.$router.push(this.pathToContinue)
        this.pathBeforeBuying = null
      } else {
        this.$router.push('/me')
      }
    }, 3000)

    if (this.is === 'success') {
      if (this.type === 'subscription') {
        // Fancy subscribe FREE or PAID (propellerads)
        helpers.addTextScript('twq(\'event\', \'tw-of10d-olx1h\', {});')

        const isProduction = process.env.NODE_ENV === 'production'

        if (isProduction) {
          // eslint-disable-next-line no-undef
          gtag('event', 'Submit', {
            event_category: 'Form',
            event_label: 'Subscription'
          })
        }
      }

      // Fancy payers (propellerads)
      if (this.type === 'credits' || this.type === 'tip') {
        helpers.addTextScript(`twq('event', 'tw-of10d-olx0p', { value: "${this.cost}" });`)
      }
    }
  },
  methods: {
    ...mapActions(useUserStore, [
      'meGet'
    ])
  }
}
</script>

