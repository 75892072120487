<script setup>
  import AppAvatar from '@/components/App/AppAvatar.vue'
  import { inject } from 'vue'
  import TipsModal from '@/components/TipsModal.vue'
  import { clientEventTrack } from '@/helpers/methods.js'
  import { countNumberFormat } from '@/helpers/app-helpers.js'
  import GiftsStarter from '@/components/Gifts/GiftsStarter.vue'
  import { useAuthStore } from '@/stores/auth.js'
  import { storeToRefs } from 'pinia'

  const authStore = useAuthStore()

  const {
    options
  } = storeToRefs(authStore)

  defineProps({
    index: {
      type: Number,
      default: 4,
      required: true
    },
    user: {
      type: Object,
      default: () => ({ photos: { items: [] } }),
      required: true
    }
  })

  const activeTabWithTimeframe = inject('activeTabWithTimeframe')
</script>

<template>
  <div class="flex items-center">
    <span class="text-sm text-gray-400 p-2">
      {{ index + 4 }}
    </span>
    
    <div class="w-full flex items-center justify-between gap-2 max-w-[90%]">
      <div class="flex items-center gap-2 min-w-0">
        <router-link
          :to="`/@${user.username}`"
          class="flex-shrink-0"
          @click="clientEventTrack({ typeName: 'ranking__profile_clicked', userId: user.userId })">
          <AppAvatar class="w-12 h-12" :user="user"/>
        </router-link>

        <div class="flex flex-col gap-1 overflow-hidden">
          <div class="flex items-center gap-1">
            <router-link
              :to="`/@${user.username}`"
              class="inline-block font-semibold whitespace-nowrap overflow-hidden text-ellipsis">
              {{ user.name ? user.name : '' }}
            </router-link>

            <InlineSvg
              :src="require('@/assets/svg/design/verified.svg')"
              class="shrink-0 ml-0.5 w-4 h-4"/>

            <div
              v-if="user.tikTokFollowerCount"
              class="flex items-center gap-0.5">
              <InlineSvg
                :src="require('@/assets/fontawesome/regular/user.svg')"
                class="shrink-0 w-2.5 h-2.5 text-gray-500"/>

              <span class="text-gray-500 text-xs font-medium">
                {{ countNumberFormat(user.tikTokFollowerCount) }}
              </span>
            </div>
          </div>

          <div
            v-if="user.rankingPositions[activeTabWithTimeframe]?.score"
            class="flex items-center gap-0.5 rounded px-0.5 bg-gray-100 max-w-max">
            <InlineSvg
              :src="require('@/assets/fontawesome/regular/gem.svg')"
              class="text-sky-600 shrink-0 w-2.5 h-2.5"/>

            <span v-if="user.rankingPositions[activeTabWithTimeframe]?.score" class="text-sky-600 text-xs font-medium">
              {{ countNumberFormat(user.rankingPositions[activeTabWithTimeframe].score) }}
            </span>
          </div>
        </div>
      </div>

      <div class="flex-shrink-0 flex items-center gap-2">
        <GiftsStarter
          v-if="options.gifts"
          v-slot="slotProps"
          :creator="user"
          icon-class="text-gray-400 hover:text-gray-500"
          type="grid">
          <button
            type="button"
            class="bg-gray-100 hover:bg-gray-200 p-1 rounded text-gray-500"
            @click="slotProps.openGiftsCatalogModal()">
            <InlineSvg
              :src="require('@/assets/fontawesome/regular/gift.svg')"
              class="w-4 h-4"/>
          </button>
        </GiftsStarter>

        <TipsModal 
          v-else-if="options.tipsEnabled"
          v-slot="slotProps"
          :creator="user">
          <button
            type="button"
            class="bg-gray-100 hover:bg-gray-200 p-1 rounded text-gray-500"
            @click="slotProps.openModal();clientEventTrack({ typeName: 'ranking__tip_clicked', userId: user.userId })">
            <InlineSvg
              :src="require('@/assets/fontawesome/regular/money-bill.svg')"
              class="w-4 h-4"/>
          </button>
        </TipsModal>


        <router-link
          :to="`/chat/${user.userId}`"
          type="button"
          class="bg-brand inline-block p-1 rounded text-white hover:bg-brand-hover"
          @click="clientEventTrack({ typeName: 'ranking__start_chat_clicked', userId: user.userId })">
          <InlineSvg
            :src="require('@/assets/fontawesome/regular/paper-plane-top.svg')"
            class="w-4 h-4"/>
        </router-link>
      </div>
    </div>
  </div>
</template>