<template>
  <notifications
    v-touch:swipe.left="swipeHandler"
    v-touch:swipe.right="swipeHandler"
    v-touch:swipe.top="swipeHandler"
    v-touch:swipe.bottom="swipeHandler"
    group="invites"
    :class="fadeOutClasses"
    :width="getScreenWidth() > 1000 ? '342px' : '100%'"
    :style="{ top: '16px' }"
    :duration="10000"
    :max="1"
    position="top left">
    <template #body="props">
      <div
        ref="messageBody"
        :data-message-id="props.item.id"
        class="fancy-message rounded-xl relative">
        <button
          class="absolute right-1 top-1 p-0 m-0"
          @click="close">
          <svg
            class="text-gray-600 opacity-50 inline-block w-6 h-6 p-1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512">
            <path
              d="m207.6 256 107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"/>
          </svg>
        </button>

        <a
          class="flex justify-center w-full no-underline cursor-pointer"
          @click="checkState(props.item.data.user); props.close()">
          <Avatar
            class="h-10 w-10 mr-2 shrink-0"
            :show-status="true"
            :user-item="props.item.data.user"/>

          <div class="flex flex-col flex-1 justify-center">
            <h3 class="mt-0 text-base">
              {{ props.item.title }}
            </h3>

            <div class="flex items-end justify-between gap-1">
              <p class="line-clamp-2 p-0 m-0 text-sm leading-[1.15rem]">
                <a :title="props.item.text">{{ props.item.text }}</a>
              </p>

              <span class="btn px-2 py-1 btn-primary text-xs">
                {{ $t('common.reply') }}
              </span>
            </div>
          </div>
        </a>
      </div>
    </template>
  </notifications>

  <Modal
    ref="subscribeModal"
    v-slot="{ closeModal, isVisible }"
    name="Subscribe"
    position="bottom"
    rounded-class="rounded-t-2.5xl"
    overflow-class="overflow-y-auto"
    content-class="bg-white px-6 pt-5 pb-4"
    :has-indents="false">
    <SubscribeContent
      :creator="creator"
      :is-visible="isVisible"
      :close="closeModal"
      @subscribed="subscribed"/>
  </Modal>
</template>

<script>
import '../../../node_modules/animate.css/animate.css'
import Avatar from '@/components/Functional/Avatar'
import { mapState, mapWritableState } from 'pinia'
import { useAuthStore } from '@/stores/auth'
import helpers from '@/helpers'
import { useCreatorStore } from '@/stores/creator'
import Modal from '@/components/Functional/Modal'
import SubscribeContent from '@/components/ModalContents/SubscribeContent'

export default {
  name: 'InviteMessage',
  components: {
    Avatar,
    Modal,
    SubscribeContent
  },
  data () {
    return {
      textWidth: 0,
      fadeOutClasses: '',
      screenWidth: 1000,
      user: null
    }
  },
  computed: {
    ...mapState(useAuthStore, [
      'isAuthenticated',
      'options',
      'authOptions'
    ]),
    ...mapWritableState(useAuthStore, [
      'signupIsVisible',
      'pathToRedirect',
      'isTokenExist'
    ]),
    ...mapWritableState(useCreatorStore, [
      'creator'
    ])
  },
  mounted () {
  },
  methods: {
    getScreenWidth () {
      return window.screen.width
    },
    swipeHandler (direction) {
      let animationDirection = ''

      if (direction === 'bottom') {
        animationDirection = 'Down'
      } else if (direction === 'top') {
        animationDirection = 'Up'
      } else if (direction === 'left') {
        animationDirection = 'Left'
      } else if (direction === 'right') {
        animationDirection = 'Right'
      }

      this.fadeOutClasses = `animate__fadeOut${animationDirection} animate__animated animate__faster`

      this.$notify({
        group: 'invites',
        clean: true
      })

      const vm = this

      setTimeout(() => {
        vm.fadeOutClasses = ''
      }, 500)
    },
    async checkState (user) {
      if (this.isAuthenticated) {
        if (user.userId) {
          const { data } = await this.wsp.sendRequest({
            data: {
              id: user.userId
            },
            method: 'user.get'
          })

          if (data?.user) {
            const creator = data.user

            const recommendedDialogNotification = this.authOptions?.clickDestinations?.recommendedDialogNotification

            if (recommendedDialogNotification === 'profile') {
              this.$router.push(`/@${creator.username}`)

              if (!creator.subscribed) {
                await new Promise(resolve => setTimeout(resolve, 500))
                this.$refs.subscribeModal.openModal()
              }
            } else if (recommendedDialogNotification === 'chat') {
              this.$router.push(`/chat/${creator.userId}`)
            } else {
              if (creator.subscribed || !this.authOptions.chatRequiresSubscription) {
                this.$router.push(`/chat/${creator.userId}`)
              } else if (!creator.subscribed) {
                this.$router.push(`/@${creator.username}`)
                await new Promise(resolve => setTimeout(resolve, 500))
                this.$refs.subscribeModal.openModal()
              } else {
                this.$router.push(`/@${creator.username}`)
              }
            }
          }

          this.recommendedDialogClicked(user.userId, 'notification')
        }
      } else {
        this.pathToRedirect = `/@${user.username}`

        if (this.$route.fullPath.includes('@')) {
          helpers.showOnbordingOrSignUp()
        } else {
          helpers.showSignUp()
        }
      }
    },
    close () {
      this.$notify({
        group: 'invites',
        clean: true
      })
    },
    async subscribed (user) {
      await this.$router.push(`/chat/${user.userId}`)
    },
    async recommendedDialogClicked (userId, elementType) {
      const res = await this.wsp.sendRequest({
        data: {
          userId,
          elementType
        },
        method: 'recommendedDialog.clicked'
      })

      console.log(res)
    }
  }
}
</script>

<style scoped>
  .fancy-message {
    box-shadow: 0 0.3rem 0.8rem rgba(0, 0, 0, 0.13);
    background: #FFFFFF;
    border-radius: 10px;
    padding: 12px 15px;
    margin: 0 20px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
