<template>
  <div class="mb-6">
    <div class="flex items-start justify-between mb-5">
      <h3 class="h1" v-html="$t('modals.gift.heading')"/>

      <button
        class="btn py-2 pl-3 pr-0 cursor-pointer"
        type="button"
        @click="$emit('close')">
        <ImageLoader v-slot="{ setLoaded }">
          <InlineSvg
            :src="crossSVG"
            class="text-gray-100"
            @loaded="setLoaded"/>
        </ImageLoader>
      </button>
    </div>

    <div class="flex mb-5">
      <p class="leading-6" v-html="$t('modals.gift.description', { free_credits: freeCredits })"/>

      <p class="w-40 block ">
        <img
          alt="Gift"
          class="mx-auto mr-0"
          src="/img/gift.png">
      </p>
    </div>

    <AppPaymentEmailForm
      v-if="!user.email"
      ref="emailForm"
      class="my-4"/>

    <div class="mb-5">
      <button
        :disabled="loading"
        type="button"
        class="btn btn-block btn-primary mb-3 cursor-pointer"
        @click="addCard()">
        {{ $t('modals.gift.get_them_now') }}
      </button>

      <button
        type="button"
        class="btn btn-block btn-secondary"
        @click="$emit('close')">
        {{ $t('modals.gift.no_thanks') }}
      </button>
    </div>
  </div>
</template>

<script>

import ImageLoader from '@/components/Functional/ImageLoader'
import crossSVG from '@/assets/svg/design/cross.svg'
import { mapWritableState, mapState, mapActions } from 'pinia'
import { useAuthStore } from '@/stores/auth.js'
import { useUserStore } from '@/stores/user.js'
import { usePaymentStore } from '@/stores/payment'
import AppPaymentEmailForm from '@/components/App/AppPaymentEmailForm.vue'

export default {
  name: 'SignUpGiftForm',
  components: {
    ImageLoader,
    AppPaymentEmailForm
  },
  emits: ['close'],
  data () {
    return {
      crossSVG: crossSVG,
      loading: false
    }
  },
  computed: {
    ...mapWritableState(useUserStore, [
      'user'
    ]),
    ...mapWritableState(useAuthStore, [
      'showGiftPopup'
    ]),
    ...mapState(usePaymentStore, [
      'trialCreditsForBankCard'
    ]),
    creditCount () {
      return this.trialCreditsForBankCard.creditCount
    },
    freeCredits () {
      return `<span class="font-semibold text-white gift-size-decoration">
                ${this.$t('modals.gift.free_credits', { free_credits: this.creditCount })}
              </span>`
    }
  },
  mounted () {
    this.showGiftPopup = false
  },
  methods: {
    ...mapActions(usePaymentStore, [
      'checkoutSessionCreate'
    ]),
    async addCard () {
      // Check email
      if (!this.user.email) {
        const hasEmail = await this.$refs.emailForm.checkEmail()
        if (!hasEmail) {
          this.loading = false
          return
        }
      }

      await this.checkoutSessionCreate({
        successUrl: `${window.origin}/checkout/result?is=success&type=add-card`,
        cancelUrl: `${window.origin}/checkout/result?is=cancel&type=add-card`,
        savePaymentMethod: true
      })
    }
  }
}
</script>

<style>
  .gift-size-decoration {
    background: linear-gradient(270deg, #F23459 -8.94%, #C01855 144.79%);
    padding: 2px;
    padding-right: 20px;
    padding-left: 8px;
    clip-path: polygon(0% 0%, 100% 0, 93% 50%, 100% 100%, 0% 100%);
  }
</style>
