<script setup>
  import { ref, onMounted, onUnmounted, onBeforeMount, computed, defineExpose } from 'vue'
  import { useAuthStore } from '@/stores/auth.js'
  import { storeToRefs } from 'pinia'
  import app from '@/main.js'
  import { clientEventTrack } from '@/helpers/methods.js'

  // Feature: https://admin.1.ts.fansy.lol/features/CreditPricingLto

  const authStore = useAuthStore()
  const { expirationTs } = storeToRefs(authStore)

  const diff = ref(null)

  const updateCountdown = () => {
    if (!expirationTs.value) {
      return
    }

    const now = app.$dayjs().unix()
    diff.value = expirationTs.value - now

    if (diff.value <= 0) {
      diff.value = 0 // Stop the countdown at 00:00:00
      window.location.reload()
    }
  }

  let timer = null

  onBeforeMount(() => {
    updateCountdown()
  })

  onMounted(() => {
    updateCountdown()
    timer = setInterval(updateCountdown, 1000)

    if (expirationTs.value && diff.value > 0) {
      console.log(123)
      clientEventTrack({ typeName: 'credit_pricing_lto_popup_shown' })
    }
  })

  const timeLeft = computed(() => {
    return app.$dayjs.duration(diff.value, 'seconds').format('HH:mm:ss')
  })

  onUnmounted(() => {
    if (timer) clearInterval(timer)
  })

  defineExpose({ diff })
</script>

<template>
  <div
    v-if="expirationTs && diff > 0"
    class="bg-gray-50 rounded px-2.5 py-3 flex items-center justify-between gap-1">
    <div class="flex items-center gap-2">
      <InlineSvg
        class="w-6 h-6 text-primary"
        :src="require('@/assets/fontawesome/regular/gift.svg')"/>

      <span class="text-primary text-2xl font-bold uppercase">
        {{ $t('limited.offer') }}
      </span>
    </div>

    <div class="flex flex-col gap-0.5 text-right">
      <span class="font-medium text-[14px] text-gray-500 leading-4">
        {{ $t('limited.offer.expires.in') }}
      </span>

      <span class="font-bold text-xs text-gray-500 leading-4 font-mono">
        {{ timeLeft }}
      </span>
    </div>
  </div>
</template>

<style scoped>
  .credits-shadow {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
  }
</style>