<template>
  <div>
    <SearchInput
      v-model:text-changed="search"
      :placeholder="$t('user.subscriptions.search_field')"
      class="mb-4"/>

    <h3
      v-if="!subscriptions.length && !loading"
      class="font-bold text-sm text-gray-400 text-center">
      {{ $t('user.subscriptions.no_subscriptions') }}
    </h3>

    <h3
      v-if="subscriptions.length && !filteredSubscriptions.length && !loading"
      class="font-bold text-sm text-gray-400 text-center">
      {{ $t('user.subscriptions.users_not_found') }}
    </h3>

    <div
      v-for="(item) in filteredSubscriptions"
      :key="item.publisherUserId"
      class="flex items-center justify-center py-2.5 border-b border-gray-100">
      <router-link
        class="h-10 w-10 mr-2"
        :to="`/@${item.publisherUser.username}`">
        <Avatar :user-item="item.publisherUser"/>
      </router-link>

      <div class="flex-1 flex justify-between">
        <router-link :to="`/@${item.publisherUser.username}`">
          <h3 class="overflow-ellipsis overflow-hidden text-sm font-semibold">
            {{ item.publisherUser.name }}
          </h3>

          <span
            v-show="item.publisherUser.username"
            class="text-gray-400">@{{ item.publisherUser.username }}</span>
        </router-link>

        <button
          v-if="item.publisherUser.subscribed"
          class="btn btn-primary px-3 py-0 text-base m-0 self-center min-w-[6rem] lowercase"
          @click="$refs.unsubscribeModal.openModal({ userId: item.publisherUser.userId })">
          {{ $t('user.subscriptions.unfollow') }}
        </button>

        <button
          v-else
          class="btn btn-secondary px-3 py-0 text-base m-0 self-center min-w-[6rem]"
          @click="subscribe({
            userId: item.publisherUser.userId,
            price: item.price,
            user: item.publisherUser })">
          {{ item.price ? `${getSubscriptionPrice(item)}$/${getSubscriptionPeriod(item)}` : $t('user.subscriptions.for_free') }}
        </button>

        <!--{{ item.publisherUser.subscriptionPlans[0].priceInCurrency }}-->
      </div>
    </div>

    <Spinner v-show="loading"/>

    <Modal
      v-slot="{ values, closeModal }"
      ref="unsubscribeModal"
      name="Unsubscribe">
      <UnsubscribeContent
        :values="values"
        @unsubscribed="unsubscribed"
        @close="closeModal()"/>
    </Modal>

    <Modal
      ref="subscribeModal"
      v-slot="{ closeModal, isVisible }"
      name="Subscribe"
      position="bottom"
      rounded-class="rounded-t-2.5xl"
      overflow-class="overflow-y-auto"
      content-class="bg-white px-6 pt-5 pb-4"
      :has-indents="false">
      <SubscribeContent
        :creator="selectedUser"
        :is-visible="isVisible"
        :close="closeModal"
        @subscribed="subscribed"/>
    </Modal>
  </div>
</template>

<script>
import SearchInput from '@/components/Subscriptions/SearchInput'
import Avatar from '@/components/Functional/Avatar'
import Spinner from '@/components/Functional/Spinner'
import Modal from '@/components/Functional/Modal'
import UnsubscribeContent from '@/components/ModalContents/UnsubscribeContent'
import { mapActions, mapState } from 'pinia'
import { useSubscriptionsStore } from '@/stores/subscriptions'
import app from '@/main'
import { useCreatorStore } from '@/stores/creator'
import SubscribeContent from '@/components/ModalContents/SubscribeContent'

export default {
  name: 'TheSubscriptions',
  components: {
    SearchInput,
    Avatar,
    Spinner,
    Modal,
    UnsubscribeContent,
    SubscribeContent
  },
  data () {
    return {
      search: '',
      loading: true,
      selectedUser: {}
    }
  },
  computed: {
    ...mapState(useSubscriptionsStore, [
      'subscriptions'
    ]),
    filteredSubscriptions () {
      if (this.search) {
        // Filter by name, username
        return this.subscriptions.filter((item) => {
          const user = item.publisherUser

          return [user.name, user.username].some((item) => {
            return item ? item.includes(this.search) : false
          })
        })
      }

      return this.subscriptions
    }
  },
  beforeMount () {
    this.loading = true

    this.subscriptionsGet().finally(() => {
      this.loading = false
    })
  },
  methods: {
    ...mapActions(useSubscriptionsStore, [
      'subscriptionsGet',
      'toggleSubscriptionStatus'
    ]),
    ...mapActions(useCreatorStore, [
      'purchaseSubscription'
    ]),
    getSubscriptionPrice (subscription) {
      const subscriptionPlans = subscription.publisherUser.subscriptionPlans

      return subscriptionPlans?.length ? subscriptionPlans[0].priceInCurrency : subscription.price
    },
    getSubscriptionPeriod (subscription) {
      const subscriptionPlans = subscription.publisherUser.subscriptionPlans
      const periods = {
        w: 'week',
        m: 'month',
        y: 'year'
      }

      if (subscriptionPlans?.length) {
        const period = subscriptionPlans[0].period
        return periods[period]
      } else {
        return 'month'
      }
    },
    subscribed (user) {
      this.toggleSubscriptionStatus(user.userId)
    },
    unsubscribed (user) {
      this.toggleSubscriptionStatus(user.userId)
    },
    async subscriptionCreate (userId) {
      const res = await app.wsp.sendRequest({
        data: {
          publisherUserId: userId
        },
        method: 'subscription.create'
      })

      if (res.error) {
        console.log(res.error?.message)
        return
      }

      const isProduction = process.env.NODE_ENV === 'production'

      if (isProduction) {
        // eslint-disable-next-line no-undef
        gtag('event', 'Submit', {
          event_category: 'Form',
          event_label: 'Subscription'
        })
      }

      this.toggleSubscriptionStatus(userId)
    },
    subscribe ({ userId, price = null, user = {} }) {
      if (price) {
        this.selectedUser = user

        if (this.selectedUser.username) {
          this.$refs.subscribeModal.openModal()
        }
      } else {
        this.subscriptionCreate(userId)
      }
    }
  }
}

</script>

