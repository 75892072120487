<template>
  <Form
    id="credits-form-1"
    ref="proceedToPaymentForm"
    v-slot="{ errors, setErrors, handleSubmit }"
    :initial-values="initialValues"
    @submit="proceedToPayment">
    <div
      :class="[isMobile ? 'scrollbar-none' : 'scrollbar-thin']"
      class="grid grid-flow-col auto-cols-max overflow-auto -mx-1 mb-4 pt-3">
      <div
        v-for="(item, index) in sortedProducts"
        :key="item.id"
        type="button"
        :tabindex="index"
        class="h-[8rem] sm:h-34 w-[100px] sm:w-[115px] m-1 relative rounded-lg flex flex-col justify-between cursor-pointer"
        :class="[
          item.id === selectedProductId ? 'shadow-as-border' : 'shadow-as-border-gray',
        ]"
        @click="selectTariff(item, handleSubmit, $event)">
        <header class="w-full h-4/6 rounded-t-lg flex bg-white flex-col items-center justify-center relative">
          <div
            v-if="item.bonusCreditCount"
            class="bg-primary text-xs w-max mx-auto text-white rounded-full px-2 py-0.5 absolute -top-2.5 left-0 right-0">
            +{{ item.bonusCreditCount }} {{ $t('common.credits') }}
          </div>

          <div class="text-center relative pt-2">
            <h4 class="text-lg font-bold leading-4 flex items-center">
              {{ item.creditCount }}

              <InlineSvg
                class="h-4 w-4 ml-1"
                :src="require('@/assets/svg/design/coin.svg')"/>
            </h4>

            <small class="text-caption text-gray-900">{{ $tc('user.credits.credits', item.creditCount) }}</small>

            <div
              v-show="loading && item.id === selectedProductId"
              class="absolute left-0 right-0 -bottom-6 sk-pulse sk-center"/>
          </div>

          <div
            v-if="item.isTop"
            class="text-primary font-bold text-sm pt-1.5">
            TOP
          </div>

          <div
            v-else-if="item.discountInPercents"
            class="text-primary font-bold text-sm pt-1.5">
            SAVE {{ item.discountInPercents }}%
          </div>
        </header>

        <footer
          :class="[item.id === selectedProductId ? 'tariff-gradient' : 'bg-gray-100']"
          class="w-full h-2/6 rounded-b-lg flex flex-col items-center justify-center">
          <h5
            :class="[item.id === selectedProductId ? 'text-white' : 'text-black']"
            class="text-sm font-semibold leading-4 pt-1">
            {{ item.priceInCurrency }} {{ currency }}
          </h5>

          <div
            :class="[item.id === selectedProductId ? 'text-white' : 'text-gray-900']"
            class="text-xs whitespace-nowrap">
            ${{ item.priceInCurrencyPerCredit }}/{{ $tc('user.credits.credits') }}
          </div>
        </footer>
      </div>

      <div class="w-1"/>
    </div>

    <YupTooltip
      v-if="!paymentMethods.length"
      :i18n="errors.terms">
      <div class="flex items-start mb-1 mt-5">
        <div class="h-5 flex items-center">
          <Field
            id="terms"
            type="checkbox"
            name="terms"
            class="mt-1.5 h-4 w-4 text-primary border border-primary rounded focus:ring-blue-300"
            :value="true"
            :rules="rules.terms"
            @focus="setErrors(fieldsToReset)"/>
        </div>

        <div class="ml-3 text-sm">
          <label
            for="terms"
            class="font-medium text-gray-600 text-caption leading-4">
            <VRuntimeTemplate
              :template="$t('user.credits.terms_agreement', { terms_of_service: termsOfService, privacy_policy: privacyPolicy })"/>
          </label>
        </div>
      </div>
    </YupTooltip>

    <AppPaymentEmailForm
      v-if="!user.email"
      ref="emailForm"
      class="my-4"/>

    <div :class="{ 'mt-8': !errorMessage }">
      <template v-if="!loading">
        <AlertMessage
          v-if="isPending"
          type="warning"
          class="mb-2.5"
          heading="Transaction pending, check later or contact online support"/>

        <AlertMessage
          v-else-if="successMessage"
          type="success"
          class="mb-3"
          :heading="successMessage"/>

        <AlertMessage
          v-else-if="isFailed || errorMessage"
          type="error"
          class="mb-3"
          :heading="errorMessage || 'Previous transaction failed'"
          :text="errorDescription"/>

        <AlertMessage
          v-else-if="showWarnUpdatingCard"
          type="info"
          class="-mt-3 mb-3"
          heading="When making a payment, you will have to update your card details once more!"/>
      </template>

      <span
        v-if="showLegalInformationButton"
        v-smooth-scroll
        class="btn btn-block btn-secondary mb-3"
        href="#legal-information-1">
        {{ $t('user.credits.legal_information') }}
      </span>

      <button
        :disabled="loading || isPending"
        type="submit"
        class="btn btn-block btn-primary mb-3 h-[44px]">
        <span v-show="!loading">
          {{ $t('user.credits.proceed_to_payment') }}
        </span>

        <Spinner v-show="loading"/>
      </button>
    </div>

    <!--<pre>{{ products }}</pre>-->
  </Form>
</template>

<script>
import { mapActions, mapState, mapWritableState } from 'pinia'
import { usePaymentStore } from '@/stores/payment'
import { Field, Form } from 'vee-validate'
import { filter, round } from 'lodash'
import { useAppStore } from '@/stores/app'
import { useUserStore } from '@/stores/user'
import YupTooltip from '@/components/Functional/YupTooltip'
import Spinner from '@/components/Functional/Spinner.vue'
import AlertMessage from '@/components/Functional/AlertMessage.vue'
import uniqid from 'uniqid'
import AppPaymentEmailForm from '@/components/App/AppPaymentEmailForm.vue'
import { useAuthStore } from '@/stores/auth'

export default {
  name: 'Credits',
  components: {
    YupTooltip,
    Field,
    Form,
    Spinner,
    AlertMessage,
    AppPaymentEmailForm
  },
  props: {
    showLegalInformationButton: {
      type: Boolean,
      default: true
    }
  },
  emits: ['close'],
  data () {
    return {
      errorMessage: null,
      errorDescription: null,
      successMessage: null,
      constantLoading: true,
      loading: false,
      initialValues: {
        terms: false
      },
      fieldsToReset: {
        terms: false
      },
      rules: {
        terms: this.$yup.boolean()
          .required({ key: 'yup.custom.terms_and_conditions', values: {} })
          .oneOf([true], () => ({ key: 'yup.custom.terms_and_conditions', values: {} }))
      },
      // Sorted products
      sortedProducts: []
    }
  },
  computed: {
    ...mapState(useAppStore, [
      'isMobile',
      'currentTime',
      'endlessPaymentCreated'
    ]),
    ...mapState(usePaymentStore, [
      'activePaymentMethod',
      'paymentMethods',
      'products',
      'currency',
      'creditsPayment',
      'showWarnUpdatingCard'
    ]),
    ...mapWritableState(usePaymentStore, [
      'selectedProductId'
    ]),
    ...mapState(useUserStore, [
      'premiumSupportAgentUserId',
      'user'
    ]),
    ...mapState(useAuthStore, [
      'options'
    ]),
    secondsLeftFromPayment () {
      if (this.creditsPayment) {
        return this.currentTime - this.creditsPayment.createdAt
      }
      else {
        return null
      }
    },
    isPending () {
      return this.creditsPayment && ['created', 'payment_pending', 'payment_accepted'].includes(this.creditsPayment.status)
    },
    isFailed () {
      return this.creditsPayment && ['failed', 'cancelled'].includes(this.creditsPayment.status)
    },
    isLoading () {
      return !this.products.length || this.constantLoading
    },
    termsOfService () {
      return `<a
                href="${window.one2fan.origin}/terms"
                target="_blank"
                class="text-primary">
                {{ $t("common.terms_of_service") }}
              </a>`
    },
    privacyPolicy () {
      return `<a
                href="${window.one2fan.origin}/policy"
                target="_blank"
                class="text-primary">
                {{ $t('common.privacy_policy') }}
              </a>`
    }
  },
  watch: {
    creditsPayment (payment) {
      if (payment?.status === 'succeeded') {
        this.purchaseDelete(payment.purchaseId)

        this.successMessage = 'Payment successful'

        setTimeout(() => {
          this.$emit('close')
        }, 1500)
      }
      else if (['failed', 'cancelled'].includes(payment?.status)) {
        this.errorMessage = payment?.error || 'Payment failed'
        this.purchaseDelete(payment.purchaseId)
      }
    }
  },
  created () {
    setTimeout(() => {
      this.constantLoading = false
    }, 500)
  },
  beforeMount () {
    const selectedProduct = this.products.find((item) => item.id === this.selectedProductId)

    if (selectedProduct) {
      this.sortedProducts = filter(this.products, (item) => item.id !== this.selectedProductId)
      this.sortedProducts.unshift(selectedProduct)
    } else {
      this.selectedProductId = null
      this.sortedProducts = this.products
    }
  },
  async mounted () {
    if (this.creditsPayment) {
      const purchaseId = this.creditsPayment.purchaseId
      await this.purchaseGet(purchaseId)

      if (this.creditsPayment.status === 'created') {
        await this.purchaseCancel(purchaseId)
          .then(() => {
            this.purchaseDelete(purchaseId)
          })
      }
    }
  },
  methods: {
    ...mapActions(usePaymentStore, [
      'paymentMethodsGet',
      'checkoutSessionCreate',
      'purchase',
      'purchaseGet',
      'purchaseCreate',
      'purchaseDelete',
      'purchaseCancel'
    ]),
    round (value) {
      if (!value) {
        return ''
      }

      return round(value, 2)
    },
    async selectTariff (tariff, handleSubmit, event) {
      if (!this.loading && !this.isPending) {
        if (this.selectedProductId === null || this.selectedProductId !== tariff.id) {
          this.selectedProductId = tariff.id
        }
        else {
          await handleSubmit(event, this.proceedToPayment)
        }
      }
    },
    async buyCredits () {
      // If card saved - buy immediately
      if (this.paymentMethods.length && this.selectedProductId) {
        if (this.isPending) {
          return
        }

        // Remove failed purchase if payment initialized again
        if (['failed', 'cancelled'].includes(this.creditsPayment?.status)) {
          this.purchaseDelete(this.creditsPayment.purchaseId)
        }

        // Check payment status or init one
        if (this.creditsPayment) {
          await this.purchaseGet(this.creditsPayment.purchaseId)
        }
        else {
          await this.purchaseCreate({ id: `credits_${this.selectedProductId}_${uniqid()}` })
        }

        if (this.endlessPaymentCreated) {
          await new Promise(resolve => setTimeout(resolve, 1000 * 5))
          this.loading = false
          return
        }

        return this.purchase({
          productType: 'credits',
          productId: this.selectedProductId,
          paymentMethodId: this.activePaymentMethod.id,
          successUrl: `${window.origin}/checkout/result?is=success&type=credits&cost=${this.selectedProductId}`,
          purchaseId: this.creditsPayment.purchaseId
        })
          .then(async () => {
            this.errorMessage = null
            this.errorDescription = null
          })
          .catch((error) => {
            if (error?.code === 147) {
              this.errorMessage = error?.message
              this.errorDescription = error?.description
            }
            // Payment is pending
            else if (error?.code === 149) {
              //
            }
            else {
              this.errorMessage = 'Error occured'
            }

            // Remove payment
            if ([147, 105].includes(error?.code)) {
              this.purchaseDelete(this.creditsPayment.purchaseId)
            }
          })
          .finally(async () => {
            if (this.creditsPayment) {
              await this.purchaseGet(this.creditsPayment.purchaseId)

              // If purchase not completed -> wait n seconds to show loading
              if (!['succeeded', 'failed', 'cancelled'].includes(this.creditsPayment?.status)) {
                await new Promise(resolve => setTimeout(resolve, 1000 * 10))
              }
            }

            this.loading = false
          })
      }
      else {
        await this.checkoutSessionCreate({
          productType: 'credits',
          productId: this.selectedProductId,
          successUrl: `${window.origin}/checkout/result?is=success&type=credits&cost=${this.selectedProductId}`,
          cancelUrl: `${window.origin}/checkout/result?is=cancel&type=credits`,
          savePaymentMethod: true
        })
          .finally(() => {
            this.loading = false
          })
      }
    },
    async proceedToPayment () {
      if (!this.loading && !this.isPending) {
        this.loading = true

        // Check email
        if (!this.user.email) {
          const hasEmail = await this.$refs.emailForm.checkEmail()
          if (!hasEmail) {
            this.loading = false
            return
          }
        }

        // Check if payment method is exists and get if not
        if (!this.activePaymentMethod.id) {
          await this.paymentMethodsGet()
        }

        await this.buyCredits()
      }
    }
  }
}
</script>

<style scoped>
  /* https://codepen.io/doodack/pen/mBcIf */
  .shadow-hack:before {
    @apply rounded-lg;
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    box-shadow: 0 4px 34px #C9C9D6;
  }

  .tariff-gradient {
    background: linear-gradient(180deg, #F23459 0%, #C01855 100%), #F23459;
  }

  .shadow-as-border {
    box-shadow: 0 0 0 2px #f23459;
  }

  .shadow-as-border-gray {
    box-shadow: 0 0 0 2px #9ca3af4a;
  }
</style>
