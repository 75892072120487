<script setup>
  import ModalWindow from '@/components/Functional/ModalWindow.vue'
  import AppEnterYourEmailModal from '@/components/App/AppEnterYourEmailModal.vue'
  import { createComponent } from '@/helpers/utils.js'

  function openModal (props = {}) {
    const modalProps = {
      wrapperClass: 'max-w-md m-auto w-full h-full flex mt-4'
    }

    createComponent(ModalWindow, modalProps, AppEnterYourEmailModal, props)
  }
</script>

<template>
  <button 
    type="button"
    class="bg-sky-400 rounded-lg w-full flex items-center gap-3 py-3 px-4"
    @click="openModal">
    <img
      class="w-8"
      src="@/assets/images/emoji/envelope.png"
      alt="Envelope emoji">

    <span class="grow flex flex-col text-left">
      <span class="text-white font-bold">
        {{ $t("email.stay.close") }}
      </span>

      <span class="text-sm text-white font-semibold">
        {{ $t("email.keep.in.touch") }}
      </span>
    </span>

    <InlineSvg
      :src="require('@/assets/fontawesome/regular/chevron-right.svg')"
      class="h-7 text-white"/>
  </button>
</template>