<template>
  <div class="flex items-center justify-between mb-3.5">
    <h2 :class="[options.chatNavigationEnabled ? 'h1' : 'h2']">
      {{ balance === 0 ? $t('modals.no_credits.not_enough_credits') : $t('user.credits.sub_heading') }}
    </h2>

    <CloseCrossButton @click="emitClose"/>
  </div>

  <CreditsLimitedOfferBanner class="mb-2"/>

  <p :class="{ 'mb-5': !options.creditsPopupChatPriceEnabled }">
    {{ $t('modals.no_credits.description') }}
  </p>

  <p
    v-if="options.creditsPopupChatPriceEnabled"
    class="mb-5 mt-2 font-medium text-gray-600 text-caption leading-5">
    {{ $t('user.credits.online_minute_costs', { costOnlineChat: costOnlineChat }) }} <br>
    {{ $t('user.credits.offline_message_costs', { costOfflineChat: costOfflineChat }) }}
  </p>

  <CreditsPlans
    :show-legal-information-button="false"
    @close="emitClose"/>

  <button
    type="button"
    class="btn btn-block btn-secondary"
    @click="emitClose">
    {{ $t('common.cancel') }}
  </button>
</template>

<script>

import CloseCrossButton from '@/components/ModalContents/parts/CloseCrossButton'
import CreditsPlans from '@/components/Credits/CreditsPlans.vue'
import { mapState } from 'pinia'
import { useAuthStore } from '@/stores/auth'
import { useUserStore } from '@/stores/user'
import CreditsLimitedOfferBanner from '@/components/Credits/CreditsLimitedOfferBanner.vue'

export default {
  name: 'NoCreditsContent',
  components: {
    CloseCrossButton,
    CreditsPlans,
    CreditsLimitedOfferBanner
  },
  emits: ['close'],
  data () {
    return {}
  },
  computed: {
    ...mapState(useAuthStore, [
      'options'
    ]),
    ...mapState(useUserStore, [
      'balance',
      'rates'
    ]),
    costOfflineChat () {
      return this.rates.costOfflineChat
    },
    costOnlineChat () {
      return this.rates.costOnlineChat
    }
  },
  methods: {
    async emitClose () {
      await this.$emit('close')
    }
  }
}
</script>
