<template>
  <div class="container py-5">
    <div class="divide-y divide-gray-100 space-ty">
      <div
        v-for="(item, i) in purchases"
        :key="'purchase' + i + item.paymentProcessorTxId">
        <time
          datetime="2001-05-15T19:00"
          class="text-gray-400 text-caption">
          {{ $dayjs(item.createdAt * 1000)
            .locale($i18n.locale)
            .format('MMM, D YYYY h:mm A') }}
        </time>

        <div class="flex justify-between items-center justify-center text-sm pt-1 pb-2">
          <span class="leading-5">
            <template v-if="item?.product.type === 'subscription'">
              {{ $t('user.payments.subscription_to') }}

              <router-link
                :to="`/@${item.product.subscription.publisherUsername}`"
                class="text-primary hover:link">
                {{ item.product.subscription.publisherName }}
              </router-link>
            </template>

            <template v-else-if="item?.product.type === 'credits'">
              {{ $t('user.payments.purchased') }} <span class="text-primary">{{ item.product?.credits.creditCount }}</span> {{ $t('common.credits') }}
            </template>

            <template v-else-if="item?.product.type === 'tip'">
              {{ $t('creator.tips.you_supported') }}

              <router-link
                :to="`/@${item.product.tip.receiverUsername}`"
                class="text-primary hover:link">
                {{ item.product.tip.receiverName }}
              </router-link>
            </template>
          </span>

          <span class="font-bold">
            US ${{ item.priceInCurrency }}
          </span>
        </div>
      </div>

      <Observer @intersect="intersected"/>
    </div>

    <Spinner v-show="paginationState === 'loading'"/>

    <h3
      v-if="paginationState !== 'loading' && !purchases.length"
      class="font-bold text-sm text-gray-400 text-center">
      {{ $t('user.payments.no_payments_yet') }}
    </h3>

    <h3
      v-else-if="paginationState === 'loaded-all'"
      class="font-normal text-sm text-gray-400 text-center py-2">
      {{ $t('common.end_of_list') }}
    </h3>
  </div>
</template>

<script>
import Observer from '@/components/Functional/Observer'
import Spinner from '@/components/Functional/Spinner'
import { useUserStore } from '@/stores/user'
import { mapState } from 'pinia'

export default {
  name: 'PaymentsContent',
  components: {
    Observer,
    Spinner
  },
  data () {
    return {
      purchases: [],
      paginationState: 'loading'
    }
  },
  computed: {
    ...mapState(useUserStore, [
      'options'
    ])
  },
  mounted () {
    this.purchasesGet()
  },
  methods: {
    intersected () {
      if (this.paginationState === 'loaded') {
        this.purchasesGet(this.purchases[this.purchases.length - 1]?.number)
      }
    },
    async purchasesGet (before) {
      if (before) {
        this.paginationState = 'loading-more'
      } else {
        this.paginationState = 'loading'
      }

      const data = {
        limit: 15
      }

      if (before) {
        data.beforeNumber = before
      }

      const res = await this.wsp.sendRequest({
        data: data,
        method: 'purchases.get'
      })

      if (res.error) {
        throw res.error
      }

      const purchases = res.data.purchases || []

      this.paginationState = purchases.length > 0 ? 'loaded' : 'loaded-all'

      if (before) {
        this.purchases.push(...purchases)
      } else {
        this.purchases = purchases
      }

      const hasScrollbar = window.innerWidth > document.documentElement.clientWidth

      if (this.paginationState === 'loaded' && !hasScrollbar) {
        await this.purchasesGet(this.purchases[this.purchases.length - 1]?.number)
      }
    }
  }
}
</script>

