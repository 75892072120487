<script setup>
  import { onMounted } from 'vue'
  import Navbar from '@/components/Navbar.vue'
  import EventsSection from '@/components/Events/EventsSection.vue'
  import { clearQueriesAndAddWebviewParams } from '@/helpers/app-helpers.js'
  import CreditsLimitedOffer from '@/components/Credits/CreditsLimitedOffer.vue'

  onMounted(() => {
    clearQueriesAndAddWebviewParams()
  })
</script>

<template>
  <div class="max-w-md mx-auto px-4 mt-5 pb-20 bg-white relative">
    <EventsSection/>

    <CreditsLimitedOffer
      class="fixed z-10 -ml-1 bottom-17"/>
  </div>

  <Navbar/>
</template>
