<script setup>
  import { ref, onMounted, onUnmounted, onBeforeMount, computed, defineExpose } from 'vue'
  import { useAuthStore } from '@/stores/auth.js'
  import { storeToRefs } from 'pinia'
  import app from '@/main.js'
  import NoCreditsContent from '@/components/ModalContents/chat/NoCreditsContent.vue'

  // https://admin.1.ts.fansy.lol/features/CreditPricingLto

  const authStore = useAuthStore()
  const { expirationTs } = storeToRefs(authStore)

  const diff = ref(null)

  const updateCountdown = () => {
    if (!expirationTs.value) {
      return
    }

    const now = app.$dayjs().unix()
    diff.value = expirationTs.value - now

    if (diff.value <= 0) {
      diff.value = 0 // Stop the countdown at 00:00:00
      window.location.reload()
    }
  }

  let timer = null

  onBeforeMount(() => {
    updateCountdown()
  })

  onMounted(() => {
    updateCountdown()
    timer = setInterval(updateCountdown, 1000)
  })

  const timeLeft = computed(() => {
    return app.$dayjs.duration(diff.value, 'seconds').format('HH:mm:ss')
  })

  onUnmounted(() => {
    if (timer) clearInterval(timer)
  })

  defineExpose({ diff })

  const notEnoughCreditsModal = ref(null)
</script>

<template>
  <div
    v-if="expirationTs && diff > 0"
    class="cursor-pointer w-max bg-white credits-shadow rounded-full pl-2 pr-4 py-2 flex items-center justify-between gap-1.5"
    @click="notEnoughCreditsModal.openModal();">
    <div class="bg-primary p-1.5 rounded-full">
      <InlineSvg
        class="w-5 h-5 text-white"
        :src="require('@/assets/fontawesome/regular/gift.svg')"/>
    </div>

    <div class="flex flex-col gap-0.5">
      <span class="text-primary text-[14px] leading-4 font-bold uppercase">
        {{ $t('limited.offer') }}
      </span>

      <div class="flex gap-1 -mr-1">
        <span class="font-medium text-[12px] text-gray-500 leading-4">
          {{ $t('limited.offer.expires.in') }}
        </span>

        <span class="font-bold text-[12px] w-[50px] text-gray-500 leading-4">
          {{ timeLeft }}
        </span>
      </div>
    </div>

    <Modal
      v-slot="{ closeModal }"
      ref="notEnoughCreditsModal"
      name="NoCredits"
      position="bottom"
      overflow-class="overflow-y-auto"
      content-class="bg-white p-5"
      wrapper-class=""
      rounded-class="rounded-t-2.5xl"
      :show-close-cross="false"
      :has-indents="false">
      <!--123-->
      <NoCreditsContent @close="closeModal()"/>
    </Modal>
  </div>
</template>

<style scoped>
  .credits-shadow {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
  }
</style>