<script setup>
  import { useChatStore } from '@/stores/chat'
  import { useUserStore } from '@/stores/user'
  import { onMounted, onBeforeUnmount, computed, watch, ref } from 'vue'
  import { debounce } from 'radash'

  const props = defineProps({
    chatId: {
      type: [String, null],
      required: true
    }
  })

  const chatStore = useChatStore()
  const userStore = useUserStore()

  const userStatusFeature = computed(() => {
    return userStore.features.userStatus
  })

  let interval = null
  let typingInterval = null

  const chatStatus = ref('entered_chat')

  const resetStatusDebounced = debounce({ delay: 1000 }, () => {
    chatStore.userStatus('entered_chat', props.chatId)
    chatStatus.value = 'entered_chat'

    if (interval) {
      clearInterval(interval)
      interval = null
    }

    if (typingInterval) {
      clearInterval(typingInterval)
      typingInterval = null
    }

    interval = setInterval(() => {
      chatStore.userStatus('entered_chat', props.chatId)
    }, 1000 * userStatusFeature.value.minRequestInterval)
  })

  watch(() => chatStore.messageToSend, (newValue, oldValue) => {
    if (oldValue && !newValue) {
      chatStore.userStatus('entered_chat', props.chatId)
      chatStatus.value = 'entered_chat'
    } else if (newValue !== oldValue) {
      if (chatStatus.value !== 'typing') {
        chatStore.userStatus('typing', props.chatId)

        if (interval) {
          clearInterval(interval)
          interval = null
        }

        typingInterval = setInterval(() => {
          chatStore.userStatus('typing', props.chatId)
        }, 1000 * userStatusFeature.value.minRequestInterval)
      }

      chatStatus.value = 'typing'

      resetStatusDebounced()
    }
  })

  onMounted(() => {
    if (props.chatId) {
      chatStore.userStatus('entered_chat', props.chatId)
      chatStatus.value = 'entered_chat'

      interval = setInterval(() => {
        chatStore.userStatus('entered_chat', props.chatId)
      }, 1000 * userStatusFeature.value.minRequestInterval)
    }
  })

  onBeforeUnmount(() => {
    if (props.chatId) {
      chatStore.userStatus('left_chat', props.chatId)
      chatStatus.value = 'left_chat'

      if (interval) {
        clearInterval(interval)
        interval = null
      }

      if (typingInterval) {
        clearInterval(typingInterval)
        typingInterval = null
      }
    }
  })
</script>

<template>
  <div/>
</template>
