<template>
  <div
    v-if="isDebugVisible && (isDev || checkDomainToShowDebugger)"
    class="debug fixed top-14 right-0 bg-slate-900 text-white z-10 bg-opacity-70">
    <div
      class="absolute -top-5 right-0 bg-slate-900 bg-opacity-70 h-5 w-5 flex items-center justify-center cursor-pointer"
      @click="isDebugVisible = false">
      <InlineSvg
        class="w-3"
        :src="require('@/assets/svg/design/cross.svg')"/>
    </div>

    <div
      class="px-1 mt-1 overflow-auto h-full w-100">
      <!--<pre><small><strong>{{ formatteCurrentTime }}</strong> </small></pre>-->
      <pre v-if="userId"><small>ID {{ userId }} {{ user.username }}</small></pre>
      <pre v-if="userId"><small>name: {{ user.name }}</small></pre>
      <pre v-if="userId"><small><strong>balance:</strong> {{ user.balance }}</small></pre>
      <pre v-if="paymentMethods"><small>paymentMethods: {{ paymentMethods.length }}</small></pre>
      <pre v-if="canonicalHostname"><small>{{ canonicalHostname }}</small></pre>
      <pre><small><strong>Have email:</strong> {{ !!user.email }}</small></pre>
    </div>

    <ul class="p-1 space-y-0.5 border-gray-400">
      <li>
        <a
          class="cursor-pointer"
          @click="$refs.langSwitcher.openModal()">
          Language <span class="uppercase">{{ $i18n.locale }}</span>
        </a>
      </li>
      <li>
        <router-link
          to="/test">
          Test
        </router-link>
      </li>
      <li v-if="1">
        <router-link
          to="/me">
          Me
        </router-link>
      </li>
    </ul>

    <button
      v-if="isAuthenticated && 0"
      type="button"
      class="btn rounded-none w-full p-1 text-white mb-1 bg-amber-300 hover:bg-amber-400"
      @click="payments = []">
      Reset payments
    </button>

    <div
      v-if="isAuthenticated && 1"
      class="flex p-0.5">
      <button
        type="button"
        :class="{ 'bg-green-400': paymentProcessor === 'stripe' }"
        class="btn rounded-none p-1 w-1/2 text-white"
        @click="selectedPaymentProcessor = 'stripe'">
        Stripe
      </button>

      <button
        type="button"
        :class="{ 'bg-green-400': paymentProcessor === 'exactly' }"
        class="btn rounded-none p-1 w-1/2 text-white"
        @click="selectedPaymentProcessor = 'exactly'">
        Exactly
      </button>
    </div>

    <template v-if="0">
      <div
        v-if="!isWebView"
        class="px-0.5 pb-0.5">
        <button
          type="button"
          :class="{ 'bg-green-400': webViewSimulate, 'bg-gray-500': !webViewSimulate }"
          class="btn rounded-none p-1 text-white text-center w-full flex flex-col leading-3"
          @click="webViewSimulate = !webViewSimulate">
          Webview {{ webViewSimulate ? 'ON' : 'OFF' }}
          <small>{{ webViewSimulate ? 'tiktok' : '–' }}</small>
        </button>
      </div>

      <div
        v-else
        class="p-1">
        <pre>webviewType: {{ typeOfWebview }}</pre>
        <pre>escapeToken: {{ !!webViewEscape.signinToken.value }}</pre>
      </div>
    </template>

    <div
      v-if="isAuthenticated && 0"
      class="flex mt-1">
      <button
        type="button"
        :class="{ 'bg-orange-400': endlessPaymentCreated, 'bg-gray-300': !endlessPaymentCreated }"
        class="btn rounded-none p-1 w-full text-white"
        @click="endlessPaymentCreated = !endlessPaymentCreated">
        Endless created
      </button>
    </div>

    <div
      v-if="isAuthenticated && 0"
      class="mb-1">
      <button
        type="button"
        :disabled="!quizAllowed"
        class="btn rounded-none p-1 w-full text-white bg-amber-300 mb-1"
        @click="surveyOpen = true">
        Open Quize
      </button>

      <button
        type="button"
        :disabled="quizAllowed"
        class="btn rounded-none p-1 w-full text-white bg-sky-300"
        @click="quizReset = true; survey.showAfterSignup = true">
        Reset Quiz
      </button>
    </div>

    <div
      v-if="1"
      class="flex flex-col">
      <button
        type="button"
        :class="[secretToken ? 'bg-green-400' : 'bg-orange-300']"
        class="btn rounded-none p-1  text-white mb-1"
        @click="secretToken ? secretToken = null : secretToken = '198o3z580e74koo0'">
        Toggle secret
      </button>

      <button
        type="button"
        :class="[altSecretState ? 'bg-green-400' : 'bg-orange-300']"
        class="btn rounded-none p-1  text-white mb-1"
        @click="altSecretState = !altSecretState">
        Toggle altSecret
      </button>
    </div>

    <Modal
      v-slot="{ closeModal }"
      ref="langSwitcher"
      position="bottom"
      overflow-class="overflow-y-auto"
      rounded-class="rounded-t-2.5xl"
      :has-indents="false"
      name="LangSwitcher">
      <LangSwitcherContent @close="closeModal()"/>
    </Modal>
  </div>
</template>

<script>

import { mapState, mapWritableState } from 'pinia'
import { useUserStore } from '@/stores/user'
import { useLogStore } from '@/stores/logbook'
import { useAuthStore } from '@/stores/auth'
import { useFeedStore } from '@/stores/feed'
import { useChatStore } from '@/stores/chat'
import { useCreditsStore } from '@/stores/credits'
import { useCreatorStore } from '@/stores/creator'
import { useAppStore } from '@/stores/app'
import { useSubscriptionsStore } from '@/stores/subscriptions'
import i18nHelpers from '@/i18nHelpers'
import LangSwitcherContent from '@/components/ModalContents/LangSwitcherContent'
import { usePaymentStore } from '@/stores/payment'
import { useCreatorsCatalogStore } from '@/stores/creatorsCatalog'
import { useQuizStore } from '@/stores/quiz'
import dayjs from 'dayjs'
import { isWebViewFullCheck, webviewType } from '@/helpers/is-webview'

export default {
  name: 'Debug',
  components: {
    LangSwitcherContent
  },
  data () {
    return {
      packageVersion: localStorage.getItem('packageVersion'),
      browserLocale: i18nHelpers.getBrowserLocale({ countryCodeOnly: true }),
      isWebView: isWebViewFullCheck(),
      typeOfWebview: webviewType(),
      currentTimestamp: Date.now()
    }
  },
  computed: {
    isDev () {
      return process.env.VUE_APP_IS_DEBUG === 'yes'
    },
    ...mapState(useCreatorsCatalogStore, [
      'searchInputFocused',
      'searchFormOpen',
      'sexFilter',
      'acitiveTab'
    ]),
    ...mapWritableState(useQuizStore, [
      'survey',
      'surveyOpen',
      'surveyWasOpened',
      'quizReset'
    ]),
    ...mapState(useQuizStore, [
      'quizAllowed',
      'surveyCause'
    ]),
    ...mapState(usePaymentStore, [
      'paymentProcessor',
      'products',
      'selectedProduct',
      'paymentMethods',
      'activePaymentMethod',
      'pathBeforeBuying',
      'oneClickPurchaseLoading',
      'previousPaymentProcessor',
      'showWarnUpdatingCard'
    ]),
    ...mapWritableState(usePaymentStore, [
      'selectedPaymentProcessor',
      'payments'
    ]),
    ...mapWritableState(useAppStore, [
      'isDebugVisible',
      'altSecretState',
      'endlessPaymentCreated',
      'webViewSimulate',
      'isiOS',
      'secretToken'
    ]),
    ...mapWritableState(useUserStore, [
      'user'
    ]),
    ...mapState(useAppStore, [
      'isIPhone',
      'isiOS',
      'isCurrentDevicePWA',
      'isPWAInstalled',
      'showAppealPWA',
      'isAbleToInstallPWA',
      'pwaPromptsCounter',
      'showCookiesReminder',
      'onboardingShown',
      'isDatingEnabled',
      'currentTime',
      'openDisableEmailNotificationsPopup'
    ]),
    ...mapState(useUserStore, [
      'unreadMessagesCounter',
      'unreadMessagesCounter',
      'premiumSupportAgentUserId'
    ]),
    ...mapState(useUserStore, [
      'balance',
      'userId',
      'chatPriceEnabled'
    ]),
    ...mapState(useCreatorStore, [
      'subscribed'
    ]),
    ...mapState(useLogStore, [
      'latestEvents'
    ]),
    ...mapState(useFeedStore, [
      'publicFeed',
      'publicViewedIds',
      'privateViewedIds',
      'loadingStatus'
    ]),
    ...mapState(useAuthStore, [
      'reloadTimestamp',
      'canonicalClientOrigin',
      'isAddToHomeAllowed',
      'pathToRedirect',
      'options',
      'isConnected',
      'isTokenExist',
      'isAuthenticated',
      'token',
      'signupIsVisible',
      'trackingQuery',
      'landingPath',
      'trialCreditsForBankCard',
      'hrefOrigin',
      'installId',
      'isUserFeedEnabled',
      'beforeAuthOptions',
      'authOptions',
      'signinToken',
      'webViewEscape',
      'canonicalHostname'
    ]),
    ...mapState(useChatStore, [
      'paginationState',
      'currentTTL'
    ]),
    ...mapState(useCreditsStore, [
      'savedCard'
    ]),
    ...mapState(useSubscriptionsStore, [
      'recentSubscriptions',
      'userHasPaidSubscription'
    ]),
    formatteCurrentTime () {
      return dayjs.unix(this.currentTime).format('D MMM YYYY HH:mm')
      // return this.currentTime
    },
    checkDomainToShowDebugger () {
      return [
        'alt-dev--fansy.netlify.app',
        'dev--fansy.netlify.app',
        '1.ts.fansy.lol',
        '1-ts--fansy.netlify.app',
        'link-edits--fansy.netlify.app'
      ].includes(window.location.hostname)
    }
  },
  mounted () {
  },
  methods: {}
}
</script>

<style scoped>
  li {
    @apply leading-4 text-sm
  }

  pre {
    @apply leading-4 text-sm py-0
  }
</style>
