<script setup>
  import { ref, computed, defineExpose } from 'vue'
  import app from '@/main.js'
  import ValidationTooltip from '@/components/Functional/ValidationTooltip.vue'
  import { string, object } from 'yup-next'
  import AppErrorMessage from '@/components/App/AppErrorMessage.vue'
  import { useUserStore } from '@/stores/user.js'

  const loading = ref(false)
  const isSuccessful = ref(false)

  const errorMessage = ref('')

  const formValues = ref({
    email: null
  })

  const formErrors = ref({
    email: null
  })

  const isLoading = computed(() => {
    return loading.value
  })

  const formSchema = object({
    email: string()
      .label(app.$i18n.t('forms.login.labels.email'))
      .email()
      .required()
  })

  const userStore = useUserStore()

  async function checkEmail () {
    if (isLoading.value || isSuccessful.value) {
      return
    }

    loading.value = true

    // Email validation
    try {
      await formSchema.validate(formValues.value, {
        abortEarly: false
      })
    } catch (error) {
      for (const item of error.inner) {
        if (!item.path) {
          continue
        }

        formErrors.value[item.path] = item.message
      }

      loading.value = false

      return false
    }

    // Check if email is already in use and save it
    try {
      const { data, error } = await app.wsp.sendRequest({
        data: {
          email: formValues.value.email
        },
        method: 'me.save'
      })


      errorMessage.value = ''

      if (error) {
        errorMessage.value = error?.message ? error.message : 'Unknown error'
        loading.value = false

        return false
      } else {
        userStore.user = data
        isSuccessful.value = true
      }
    } catch (error) {
      errorMessage.value = error?.message ? error.message : 'Unknown error'

      return false
    } finally {
      loading.value = false
    }

    return true
  }

  const emailInput = ref(null)

  defineExpose({
    checkEmail
  })
</script>

<template>
  <div>
    <h3 class="mb-2 text-sm !font-semibold">
      {{ app.$i18n.t('email.to.get.receipt') }}
    </h3>

    <form @submit.prevent="checkEmail">
      <div class="flex items-center justify-between gap-2 mb-3">
        <label
          for="enter-your-email-input"
          class="text-gray-950 text-sm">
          {{ app.$i18n.t('forms.login.labels.email') }}
        </label>

        <div class="relative w-full max-w-[66%]">
          <input
            id="enter-your-email-input"
            ref="emailInput"
            v-model="formValues.email"
            name="email"
            type="text"
            autocomplete="email"
            :disabled="isLoading || isSuccessful"
            :class="{
              'ring-2 ring-inset ring-red-300': formErrors.email,
              'opacity-50 cursor-not-allowed': isLoading || isSuccessful
            }"
            :placeholder="app.$i18n.t('forms.login.placeholders.email')"
            class="w-full px-4 py-3 border-none placeholder-gray-400 bg-gray-100 rounded-sm focus:ring-2 focus:ring-inset focus:ring-blue-300"
            @focus="formErrors.email = ''">

          <ValidationTooltip
            :text="formErrors.email"
            @click="formErrors.email = '';emailInput.focus()"/>
        </div>
      </div>

      <AppErrorMessage
        v-if="errorMessage"
        class="mt-2"
        :message="errorMessage"/>
    </form>
  </div>
</template>