<script setup>
  import { onUnmounted, provide } from 'vue'
  import { onCodeDown, key_Escape } from 'on-hotkey'

  const props = defineProps({
    wrapperClass: {
      type: String,
      default: 'max-w-md mx-auto w-full h-full flex'
    },
    allowCloseByClickOutside: {
      type: Boolean,
      default: true
    },
    isOpen: {
      type: Boolean,
      required: true
    },
    close: {
      type: Function,
      required: true
    }
  })

  const unsubscribeEscapeKey = onCodeDown(key_Escape, () => {
    props.close()
  })

  provide('close', props.close)

  onUnmounted(() => {
    unsubscribeEscapeKey()
  })
</script>

<template>
  <div
    class="relative z-10"
    role="dialog"
    aria-modal="true"
    @click="close(true)">
    <!-- Overlay -->
    <transition name="fade">
      <div
        v-if="isOpen"
        class="fixed inset-0 bg-black bg-opacity-70"/>
    </transition>

    <!-- Modal content -->
    <transition name="fade-appear">
      <div
        v-if="isOpen"
        class="fixed inset-0 z-50 w-screen overflow-hidden flex">
        <div
          :class="[wrapperClass]"
          class="relative">
          <slot/>
        </div>
      </div>
    </transition>
  </div>
</template>

<!--suppress CssUnusedSymbol -->
<style scoped>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.2s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }

  .fade-appear-enter-active {
    transition: opacity 0.2s ease;
  }

  .fade-appear-enter-from {
    opacity: 0;
  }
</style>
